/*Counter Section*/

.bg {
    background-image: $counter-bg-img;
    background-attachment: fixed;
    background-position: 20% 100px;
    height: 100%;
    width: 100%;
    padding: 100px 0;
    position: relative;
    background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    color: white;
    -webkit-backface-visibility: hidden;
}

.bg-overlay {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: $counter-bg-img-opacity;
    z-index: 2;
}

.act-counter {
    z-index: 3;
    position: relative;
    text-align: center;
}

.top-heading-border {
    color: $counter-color-a-four;
    border-style: dashed none;
    padding: 2px 50px;
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 2px;
    visibility: visible;
}

.counter-top-heading {
    color: $counter-color-a-three;
    font-family: $counter-b-fontFamily;
    font-size: $counter-b-fontSize;
    font-style: $counter-b-fontStyle;
    font-weight: $counter-b-fontBold;
    padding: 5px;
}

.act-h1-counter {
    font-size: $counter-a-fontSize;
    font-style: $counter-a-fontStyle;
    font-weight: $counter-a-fontBold;
    font-family: $counter-a-fontFamily;
    color: $counter-color-a-three;
    letter-spacing: 3px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.act-counter-timer {
    margin-top: 65px !important;
}

.days {
    font-size: 25px;
    line-height: 33px;
    color: #fff;
}

.time {
    font-size: 11px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
}

.counter-circle {
    text-align: center;
    margin: auto;
    padding: 40px 0 0 0;
    width: 125px;
    height: 125px;
    border: 1px solid white;
    border-radius: 20%;
}



/*ACT Heart Divider Section*/
.act-heart-divider {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}


.counter-side-line {
    width: 50px;
    height: 8px;
    display: inline-block;
    border-top: solid 1px $counter-color-b-two;
    margin: 0px 5px 0px 5px;
}

.counter-heart-left {
    color: $counter-color-b-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
}

.counter-heart-right {
    color: $counter-color-b-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
    margin-left: -7px;
}

.counter-heart-center {
    color: $counter-color-b-one;
    margin-left: -7px;
    font-size: 18px !important;
    position: relative;
    z-index: 3;
    padding: 2px;
}
