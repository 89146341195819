
.sidenav > span {
    cursor: pointer;
}
 
.set-tabs {

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color:gray;
        background-color: #fff;
        border-color: none;
        border-radius: 0px;
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
        border-radius: 0px;
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
        color:white;
        border-radius: 0px;
    }
}

.cutomize-bold-checkbox {
    width: 20px;
    margin-top: 6px !important;
    height: 20px;
}

.sidenav .themesettings {
    position: fixed;
    z-index: 1000;
    left: 0px;
    transition: 0.3s;
    padding: 11px;
    text-decoration: none;
    font-size: 20px;
    color: white;
}

.sidenav .themesettings:hover {
    left: 0;
    transition: 0.1s;
    padding: 12px;
    text-decoration: none;
    color: white;
    font-size: 22px;
}

/*Cutomize KIT styles*/
.default-chkbox{
    margin-left:5px;
    cursor:pointer;
}
.st-cust-img {
    width: 180px;
    padding: 10px;
    cursor: pointer;
    display: block;
}

.input-color {
    width: 30px;
}

.cus-inactive {
    display: none;
}

.cus-active {
    display: block;
}

.setting-title{
    color: white !important;
}

.st-sec-cust {
    background-color: rgb(76 71 71 / 38%);
    margin-left: 12px;
    padding: 10px;
    margin: 10px;
    margin-bottom: 25px !important;

    label {
        color: white;
        margin-bottom: 5px;
    }

    h4 {
        color: white;
        margin-bottom: 5px;
    }
}

.fa-close {
    cursor: pointer;
}

select {
    cursor: pointer;
}

select > option {
    cursor: pointer;
}

input {
    cursor: pointer;
}

.drp-font-family-1 {
    font-family: 'Poppins', cursive;
}

.drp-font-family-2 {
    font-family: sans-serif;
}

.drp-font-family-3 {
    font-family: cursive;
}

.drp-font-family-4 {
    font-family: 'Raleway', sans-serif;
}

.drp-font-family-5 {
    font-family: 'Audiowide', sans-serif;
}

.drp-font-family-6 {
    font-family: 'Sofia', sans-serif;
}

.drp-font-family-7 {
    font-family: 'Trirong', sans-serif;
}

.drp-font-family-8 {
    font-family: monospace;
}

.drp-font-family-9 {
    font-family: Montserrat;
}

.drp-font-family-10 {
    font-family: Roboto;
}

.drp-font-family-11 {
    font-family: Bitter;
}

.inactive-kit {
    display: none !important;
}

.st-img {
    width: 180px;
    padding: 10px;
    cursor: pointer;
    display: block;
}

.st-img-div:hover {
    opacity: 0;
}

.st-img-div {
    position: relative;
}

.st-img-div:hover + .st-sec {
    display: block;
    margin-top: -217px;
}

.st-sec {
    display: none;
    background-color: rgb(128 128 128 / 39%);
    margin-left: 12px;
    width: 160px;
}

.st-fa-download {
    position: absolute;
    top: 20px;
    left: 20px;
    color: lawngreen;
    font-size: 25px;
}

.st-label-text {
    color: white;
    padding-left: 10px;
    padding-top: 10px;
}


.color-box {
    width: 20px !important;
    height: 20px !important;
    margin-right:10px;
}

.st-font-p {
    color: white;
    font-size: 15px;
    padding-bottom: 5px;
}

.st-font-s {
    color: white;
    font-size: 15px;
    padding-bottom: 5px;
}

.st-font-c {
    color: white;
    font-size: 15px;
}

.st-social {
    padding-top: 6px;
    height: 32px;
    width: 32px;
    align-items: center;
    padding-left: 10px;
}

.st-fa {
    color: white;
    text-align: center;
    font-size: 18px;
}

.st-btn {
    padding: 11px 12px;
    color: #fff;
    font-size: 12px;
    width: 75px;
    font-weight: 400;
    margin-left: 8px;
}

.themesettings {
    top: 500px;
    background-color: #0b5abd;
}

.cn2-themesettings-convax {
    height: 100%;
    margin-top: 100px;
    background-color: #0b5abd !important;
}

.cn2-p {
    color: white;
}

button.btn-close:focus {
    outline: none;
    box-shadow: none;
}

.daymode {
    width: 55px !important;
    height: 55px;
    color: yellow;
    font-size: 30px;
    margin: 5px;
}

.nightmode {
    width: 55px !important;
    height: 55px;
    color: black;
    font-size: 30px;
    margin: 5px;
}

.green {
    width: 25px !important;
    height: 25px;
    background-color: lawngreen;
    margin: 20px;
    margin-right: 0px;
}

.orange {
    width: 25px !important;
    height: 25px;
    background-color: darkorange;
    margin: 20px;
    margin-right: 0px;
}

.purpule {
    width: 25px !important;
    height: 25px;
    background-color: rebeccapurple;
    margin: 20px;
    margin-right: 0px;
}

.gray {
    width: 25px !important;
    height: 25px;
    background-color: hotpink;
    margin: 20px;
    margin-right: 0px;
}

.settingcolor {
    cursor: pointer;
}

@media screen and (min-width: 700px) {
    .st-fa-download {
        display: none !important;
    }
}
