#cnst-about {
    position: relative;
    z-index: 1;
    background-color: #000000;
}

.about-thumb-img {
    width: 90%;
    height: 50%;
}

.cnst-h6 {
    font-size: 14px;
    font-weight: 500;
    color: #10f4d0;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #F69422;
    margin-bottom: 20px;
    padding-bottom: 5px;
    display: inline-block;
}

.cnst-h3 {
    font-size: 40px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-weight: 700;
}

.cnst-h5 { 
    font-size: 14px;
    font-weight: 500;
    color: #10f4d0;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
    padding-bottom: 5px;
    display: inline-block;
}

.cnst-h4 {
    font-size: 20px;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
}

.cnst-p {
    color: white;
    text-align: left;
    letter-spacing: 1px;
    font-size: 15px;
}

.confer-btn {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin-top: 40px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background-image: -webkit-linear-gradient(120deg, $color-secondary 0%, #505add 100%);
}

.confer-btn:hover {
    background-image: -webkit-linear-gradient(120deg, #505add 0%, $color-secondary 100%);
    color: #fff;
}

.countdown-up-area {
    padding: 40px;
}

#clock {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #ffffff;
}

#clock .box-col {
    font-size: 62px;
    font-weight: 600;
    padding: 20px 5px;
    margin: 5px;
    border: 2px solid rgba(255, 255, 255, 0.15);
    text-align: center;
    line-height: 1;
}

#clock .box-col span {
    margin-top: 10px;
    font-size: 18px;
    color: #10f4d0;
    font-weight: 500;
    display: block;
}
