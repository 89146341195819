/*Video Section*/

.act-video-bg {
    background: url('http://html.everline.templaza.net/images/home121.jpg') no-repeat 50%;
    background-attachment: fixed;
    height: 400px;
    position: relative;
    z-index: 99;
}

.act-video-bg:before {
    background: rgba(107,120,132,.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.wrap {
    background: hsla(0,0%,100%,.5);
    border: 3px solid #34a997;
    border-radius: 50%;
    height: 80px;
    left: 50%;
    line-height: 76px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 80px;
}

.wrap > .fa {
    position: relative;
    right: -3px;
    top: 3px;
}

.act-play-btn {
    color: #34a997;
    font-size: 28px !important;
}

.act-play-btn:hover {
    color: white;
}

.wrap:hover {
    background: #34a997;
    border: 3px solid white;
}
