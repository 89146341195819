
/*footer Styles*/
.footer {
    background: $footer-color-a-one;
    padding: 50px 0;
}

.footerattr aside {
    color: #fff !important;
    text-align: center;
    margin-bottom: 30px;
}

.footer .module-title {
    display: block;
    font-size: 16px;
    line-height: 51.2px;
    margin: 0 0 20px 0;
    padding: 5px 0;
    position: relative;
    text-align: center;
}

.tz_logo_widget {
    text-align: center;
}

.tz_logo_widget a {
    display: inline-block;
    padding: 40px 0 26px;
}

.tz_logo_widget p {
    font-size: 12px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    text-align: center;
    color: $footer-color-b-two;
    font-family: $footer-c-fontFamily;
    font-size: $footer-c-fontSize;
    font-style: $footer-c-fontStyle;
    font-weight: $footer-c-fontBold;
}


.footer .module-title:after {
    background: $footer-color-a-three;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -35px;
    position: absolute;
    width: 70px;
}
