.cnst-slide-content {
    text-align: center;
    background: #76bfb4;
    max-width: 380px;
    height: 380px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 25%;
    margin: 80px auto auto;
    position: relative;
    z-index: 1;
}

.cnst-slide-animation {
    animation-delay: 0ms;
    animation-duration: 1000ms;
    pointer-events: all;
}

.cnst-slide-animated-circle {
    width: 103%;
    height: 103%;
    background: #76bfb496;
    border-radius: 25%;
    position: absolute;
    left: -1.5%;
    top: -1.5%;
    animation-name: stretch;
    animation-duration: 1.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    z-index: -1;
}

@keyframes stretch {
    0% {
        transform: scale(.5);
    }

    50% {
    }

    100% {
        transform: scale(1.1);
    }
}

.cnst-h1 {
    padding: 20px;
}

.cnst-date {
    color: white;
}

.cnst-cap-top-heading {
    color: white;
    margin-bottom: 10px;
}

.carousel .carousel-inner .carousel-item img {
    height: 100vh;
    width: 100%;
}

.carousel-inner .carousel-item > img {
    -webkit-animation: thing 10s;
    -o-animation: thing 10s;
    animation: thing 10s;
}

@keyframes thing {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(1.2, 1.2);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.carousel-caption {
    bottom: 30% !important;
}
