/*-counter-section---------*/
.countdown {
    background-color: $counter-color-a-one;
    padding: 100px 0 100px;
}

.act-counter-text {
    padding-left: 63px;
    line-height: 30.8px;
    color: $counter-color-b-one;
    letter-spacing: 2px;
    font-family: $counter-a-fontFamily;
    font-style: $counter-a-fontStyle;
    font-size: $counter-a-fontSize;
    font-weight: $counter-a-fontBold;
}

.act-counter-text span {
    font-family: $counter-b-fontFamily;
    font-style: $counter-b-fontStyle;
    font-size: $counter-b-fontSize;
    font-weight: $counter-b-fontBold;
    line-height: 34px;
    color: $counter-color-b-two;
    letter-spacing: 2px;
}

.fac {
    margin:20px;
    text-align: center;
    margin: auto;
    padding: 40px 0 0 0;
    width: 125px;
    height: 125px;
    border: 1px solid $counter-color-c-one;
    border-radius: 50%;
}

.heart {
    color: $counter-color-b-two;
    font-size: 42px !important;
    line-height: 50.4px;
    padding: 25px 0 0;
    margin: 0;
    display: block;
    float: left;
}

.days {
    font-size: 25px;
    line-height: 33px;
    color: $counter-color-c-two;
}

.time {
    font-size: 11px;
    line-height: 24px;
    color: $counter-color-c-two;
    margin: 0;
    text-transform: uppercase;
}
