:root {
    --NavData-color-a-one: '';
    --NavData-color-b-one: '';
    --NavData-color-b-two: '';
    --NavData-a-fontFamily: '';
    --NavData-a-fontSize: '';
    --NavData-a-fontStyle: '';
    --NavData-a-fontBold: '';
    --SliderData-color-a-one: '';
    --SliderData-color-a-two: '';
    --SliderData-color-a-three: '';
    --SliderData-color-a-four: '';
    --SliderData-a-fontFamily: '';
    --SliderData-a-fontSize: '';
    --SliderData-a-fontStyle: '';
    --SliderData-a-fontBold: '';
    --SliderData-b-fontFamily: '';
    --SliderData-b-fontSize: '';
    --SliderData-b-fontStyle: '';
    --SliderData-b-fontBold: '';
    --SliderData-c-fontFamily: '';
    --SliderData-c-fontSize: '';
    --SliderData-c-fontStyle: '';
    --SliderData-c-fontBold: '';
    --CounterData-color-a-one: '';
    --CounterData-color-b-one: '';
    --CounterData-color-b-two: '';
    --CounterData-a-fontFamily: '';
    --CounterData-a-fontSize: '';
    --CounterData-a-fontStyle: '';
    --CounterData-a-fontBold: '';
    --CoupleData-color-a-one: '';
    --CoupleData-color-a-two: '';
    --CoupleData-color-a-three: '';
    --CoupleData-color-b-one: '';
    --CoupleData-color-b-two: '';
    --CoupleData-color-b-three: '';
    --CoupleData-a-fontFamily: '';
    --CoupleData-a-fontSize: '';
    --CoupleData-a-fontStyle: '';
    --CoupleData-a-fontBold: '';
    --CoupleData-b-fontFamily: '';
    --CoupleData-b-fontSize: '';
    --CoupleData-b-fontStyle: '';
    --CoupleData-b-fontBold: '';
    --CoupleData-c-fontFamily: '';
    --CoupleData-c-fontSize: '';
    --CoupleData-c-fontStyle: '';
    --CoupleData-c-fontBold: '';
    --GalleryData-color-a-one: '';
    --GalleryData-color-a-two: '';
    --GalleryData-a-fontFamily: '';
    --GalleryData-a-fontSize: '';
    --GalleryData-a-fontStyle: '';
    --GalleryData-a-fontBold: '';
    --WeddingPartyData-color-a-one: '';
    --WeddingPartyData-color-a-two: '';
    --WeddingPartyData-color-b-one: '';
    --WeddingPartyData-color-b-two: '';
    --WeddingPartyData-color-b-three: '';
    --WeddingPartyData-color-b-four: '';
    --WeddingPartyData-color-c-one: '';
    --WeddingPartyData-color-c-two: '';
    --WeddingPartyData-color-c-three: '';
    --WeddingPartyData-color-c-four: '';
    --WeddingPartyData-a-fontFamily: '';
    --WeddingPartyData-a-fontSize: '';
    --WeddingPartyData-a-fontStyle: '';
    --WeddingPartyData-a-fontBold: '';
    --WeddingPartyData-b-fontFamily: '';
    --WeddingPartyData-b-fontSize: '';
    --WeddingPartyData-b-fontStyle: '';
    --WeddingPartyData-b-fontBold: '';
    --WeddingPartyData-c-fontFamily: '';
    --WeddingPartyData-c-fontSize: '';
    --WeddingPartyData-c-fontStyle: '';
    --WeddingPartyData-c-fontBold: '';
    --AgendaData-color-a-one: '';
    --AgendaData-color-a-two: '';
    --AgendaData-color-a-three: '';
    --AgendaData-color-a-four: '';
    --AgendaData-color-b-one: '';
    --AgendaData-color-b-two: '';
    --AgendaData-color-b-three: '';
    --AgendaData-color-b-four: '';
    --AgendaData-color-c-one: '';
    --AgendaData-color-c-two: '';
    --AgendaData-color-c-three: '';
    --AgendaData-color-c-four: '';
    --AgendaData-a-fontFamily: '';
    --AgendaData-a-fontSize: '';
    --AgendaData-a-fontStyle: '';
    --AgendaData-a-fontBold: '';
    --AgendaData-b-fontFamily: '';
    --AgendaData-b-fontSize: '';
    --AgendaData-b-fontStyle: '';
    --AgendaData-b-fontBold: '';
    --AgendaData-c-fontFamily: '';
    --AgendaData-c-fontSize: '';
    --AgendaData-c-fontStyle: '';
    --AgendaData-c-fontBold: '';
    --SaveTheDateData-color-a-one: '';
    --SaveTheDateData-color-b-one: '';
    --SaveTheDateData-color-b-two: '';
    --SaveTheDateData-color-b-three: '';
    --SaveTheDateData-a-fontFamily: '';
    --SaveTheDateData-a-fontSize: '';
    --SaveTheDateData-a-fontStyle: '';
    --SaveTheDateData-a-fontBold: '';
    --SaveTheDateData-b-fontFamily: '';
    --SaveTheDateData-b-fontSize: '';
    --SaveTheDateData-b-fontStyle: '';
    --SaveTheDateData-b-fontBold: '';
    --SaveTheDateData-c-fontFamily: '';
    --SaveTheDateData-c-fontSize: '';
    --SaveTheDateData-c-fontStyle: '';
    --SaveTheDateData-c-fontBold: '';
    --SocialData-color-a-one: '';
    --SocialData-color-a-two: '';
    --SocialData-color-b-one: '';
    --SocialData-color-b-two: '';
    --SocialData-a-fontFamily: '';
    --SocialData-a-fontSize: '';
    --SocialData-a-fontStyle: '';
    --SocialData-a-fontBold: '';
    --FooterData-color-a-one: '';
    --FooterData-color-a-two: '';
    --FooterData-color-a-three: '';
    --FooterData-color-b-one: '';
    --FooterData-color-b-two: '';
    --FooterData-color-b-three: '';
    --FooterData-color-b-four: '';
    --FooterData-a-fontFamily: '';
    --FooterData-a-fontSize: '';
    --FooterData-a-fontStyle: '';
    --FooterData-a-fontBold: '';
    --FooterData-b-fontFamily: '';
    --FooterData-b-fontSize: '';
    --FooterData-b-fontStyle: '';
    --FooterData-b-fontBold: '';
    --FooterData-c-fontFamily: '';
    --FooterData-c-fontSize: '';
    --FooterData-c-fontStyle: '';
    --FooterData-c-fontBold: '';
    --MapData-color-a-one: '';
    --MapData-color-b-one: '';
    --MapData-color-b-two: '';
    --MapData-c-fontFamily: '';
    --MapData-c-fontSize: '';
    --MapData-c-fontStyle: '';
    --MapData-c-fontBold: '';
    --MapData-b-fontFamily: '';
    --MapData-b-fontSize: '';
    --MapData-b-fontStyle: '';
    --MapData-b-fontBold: '';
}

$navbar-color-a-one: '';
$navbar-color-b-one: '';
$navbar-color-b-two: '';
$navbar-a-fontFamily: '';
$navbar-a-fontSize: '';
$navbar-a-fontStyle: '';

$slider-color-a-one: '';
$slider-color-a-two: '';
$slider-color-a-three: '';
$slider-color-a-four: '';
$slider-a-fontFamily: '';
$slider-a-fontSize: '';
$slider-a-fontStyle: '';
$slider-a-fontBold: '';
$slider-b-fontFamily: '';
$slider-b-fontSize: '';
$slider-b-fontStyle: '';
$slider-b-fontBold: '';
$slider-c-fontFamily: '';
$slider-c-fontSize: '';
$slider-c-fontStyle: '';
$slider-c-fontBold: '';


$std-color-a-one: '';
$std-color-b-one: '';
$std-color-b-two: '';
$std-color-b-three: '';
$std-a-fontFamily: '';
$std-a-fontSize: '';
$std-a-fontStyle: '';
$std-a-fontBold: '';
$std-b-fontFamily: '';
$std-b-fontSize: '';
$std-b-fontStyle: '';
$std-b-fontBold: '';
$std-c-fontFamily: '';
$std-c-fontSize: '';
$std-c-fontStyle: '';
$std-c-fontBold: '';

$counter-color-a-one: '';
$counter-color-b-one: '';
$counter-color-b-two: '';
$counter-a-fontFamily: '';
$counter-a-fontSize: '';
$counter-a-fontStyle: '';
$counter-a-fontBold: '';

$couple-color-a-one: '';
$couple-color-a-two: '';
$couple-color-a-three: '';
$couple-color-b-one: '';
$couple-color-b-two: '';
$couple-color-b-three: '';
$couple-a-fontFamily: '';
$couple-a-fontSize: '';
$couple-a-fontStyle: '';
$couple-a-fontBold: '';
$couple-b-fontFamily: '';
$couple-b-fontSize: '';
$couple-b-fontStyle: '';
$couple-b-fontBold: '';
$couple-c-fontFamily: '';
$couple-c-fontSize: '';
$couple-c-fontStyle: '';
$couple-c-fontBold: '';

$gallery-color-a-one: '';
$gallery-color-a-two: '';
$gallery-a-fontFamily: '';
$gallery-a-fontSize: '';
$gallery-a-fontStyle: '';
$gallery-a-fontBold: '';

$wedparty-color-a-one: '';
$wedparty-color-a-two: '';
$wedparty-color-a-four: '';
$wedparty-color-b-one: '';
$wedparty-color-b-two: '';
$wedparty-color-b-three: '';
$wedparty-color-b-four: '';
$wedparty-color-c-one: '';
$wedparty-color-c-two: '';
$wedparty-color-c-three: '';
$wedparty-color-c-four: '';
$wedparty-a-fontFamily: '';
$wedparty-a-fontSize: '';
$wedparty-a-fontStyle: '';
$wedparty-a-fontBold: '';
$wedparty-b-fontFamily: '';
$wedparty-b-fontSize: '';
$wedparty-b-fontStyle: '';
$wedparty-b-fontBold: '';
$wedparty-c-fontFamily: '';
$wedparty-c-fontSize: '';
$wedparty-c-fontStyle: '';
$wedparty-c-fontBold: '';

//Agenda Section
$agenda-color-a-one: '';
$agenda-color-a-two: '';
$agenda-color-a-three: '';
$agenda-color-a-four: '';
$agenda-color-b-one: '';
$agenda-color-b-two: '';
$agenda-color-b-three: '';
$agenda-color-b-four: '';
$agenda-color-c-one: '';
$agenda-color-c-two: '';
$agenda-color-c-three: '';
$agenda-color-c-four: '';
$agenda-a-fontFamily: '';
$agenda-a-fontSize: '';
$agenda-a-fontStyle: '';
$agenda-a-fontBold: '';
$agenda-b-fontFamily: '';
$agenda-b-fontSize: '';
$agenda-b-fontStyle: '';
$agenda-b-fontBold: '';
$agenda-c-fontFamily: '';
$agenda-c-fontSize: '';
$agenda-c-fontStyle: '';
$agenda-c-fontBold: '';

$social-color-a-one: '';
$social-color-a-two: '';
$social-color-b-one: '';
$social-color-b-two: '';
$social-a-fontFamily: '';
$social-a-fontSize: '';
$social-a-fontStyle: '';
$social-a-fontBold: '';

$Map-color-a-one: '';
$Map-color-b-one: '';
$Map-color-b-two: '';
$Map-b-fontFamily: '';
$Map-b-fontSize: '';
$Map-b-fontStyle: '';
$Map-b-fontBold: '';
$Map-c-fontFamily: '';
$Map-c-fontSize: '';
$Map-c-fontStyle: '';
$Map-c-fontBold: '';


$footer-color-a-one: '';
$footer-color-a-two: '';
$footer-color-a-three: '';
$footer-color-b-one: '';
$footer-color-b-two: '';
$footer-color-b-three: '';
$footer-color-b-four: '';
$footer-a-fontFamily: '';
$footer-a-fontSize: '';
$footer-a-fontStyle: '';
$footer-a-fontBold: '';
$footer-b-fontFamily: '';
$footer-b-fontSize: '';
$footer-b-fontStyle: '';
$footer-b-fontBold: '';
$footer-c-fontFamily: '';
$footer-c-fontSize: '';
$footer-c-fontStyle: '';
$footer-c-fontBold: '';


