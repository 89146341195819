.contact-our-area {
    background-color: #151853;
    position: relative;
    z-index: 1;
}

.contact-our-area .contact-information .single-contact-info h6 {
    font-size: 18px !important;
    color: #ffffff;
    margin-bottom: 0;
    font-weight: 600;
}

.contact-our-area .contact-information .single-contact-info p {
    color: #9293bc;
    margin-bottom: 5px;
}

.contact-our-area .contact-information .single-contact-info {
    margin-bottom: 25px;
}

.contact-our-area .contact_from_area {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 30px;
    border-radius: 10px;
}

.contact_input_area .form-group .form-control {
    border-bottom: 1px solid #dbdcf1;
    color: #9293bc;
    padding: 10px;
}

.cnst-contact-row {
    margin-top: 30px !important;
    text-align: center;
}

/* Footer Section*/

.footer-area {
    position: relative;
    z-index: 1;
    background-color: #000000;
}

.main-footer-area .single-footer-widget .footer-logo {
    display: block;
    margin-bottom: 20px;
}

.main-footer-area {
    position: relative;
    z-index: 1;
}

.footer-contact-info p {
    color: white;
    font-size: 14px;
    margin-bottom: 13px;
}

.copywrite-content {
    border-top: 1px solid white;
    padding: 15px 0;
}

.main-footer-area .single-footer-widget .social-info a {
    height: 34px;
    width: 34px;
    border: 1px solid white;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    color: #ffffff;
    font-size: 15px;
    margin-right: 8px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.widget-title {
    font-size: 20px;
    color: white;
    margin-bottom: 20px !important;
    font-weight: 600 !important;
    margin-top: 10px;
}

.copywrite-text p {
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
}

.copywrite-content .copywrite-text p a {
    color: #ffffff;
    font-weight: 400;
}

.copywrite-content .copywrite-text {
    text-align: center;
    padding: 20px;
}



.act-form {
    padding: 20px;
    padding-top: 65px;
    margin-top: 95px;
}


label {
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
}

.form-group {
    margin-bottom: 30px;
}

.form-control {
    display: block;
    width: 100%;
   
    font-size: 13px;
    line-height: 1.42857143;
    color: #555 !important;
    background-color: #fff !important;
    background-image: none;
    border: 1px solid #ccc !important;
    border-radius: 0px !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

textarea .form-control {
    height: auto;
}

.footer-form-container {
    background-color: #F6F6F6;
}

.act-submit-btn {
    background-color: $color-secondary !important;
    border: solid 1px $color-secondary !important;
    color: #FFF !important;
    padding: 10px 50px !important;
    font-size: 16px !important;
}

.act-submit-btn:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #6a6a6a !important;
    text-decoration: none !important;
    border: solid 1px #6a6a6a !important;
}
