
.carousel .carousel-inner .carousel-item img {
    height: 100vh;
    width: 100%;
}

.carousel-inner .carousel-item > img {
    -webkit-animation: thing 10s;
    -o-animation: thing 10s;
    animation: thing 10s;
}


@keyframes thing {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(1.2, 1.2);
    }
}

.act-cap {
    font-family: $slider-a-fontFamily;
    font-size: $slider-a-fontSize;
    font-style: $slider-a-fontStyle;
    font-weight: $slider-a-fontBold;
    color: $slider-color-a-one;
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.carousel-caption {
    bottom: 10% !important;
}

.act-cap-and {
    font-family: $slider-a-fontFamily;
    font-size: $slider-a-fontSize;
    font-style: $slider-a-fontStyle;
    font-weight: $slider-a-fontBold;
    color: $slider-color-a-two;
    padding: 0px;
    margin: 0px;
    animation: fadeIn 5s;
    margin-top: -25px;
    margin-bottom: -20px;
}

.slider-top-heading-border {
    color: $slider-color-a-four;
    border-style: dashed none;
    padding: 2px 50px;
    display: inline-block;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 2px;
    visibility: visible;
}

.slider-top-heading {
    color: $slider-color-a-three;
    font-family: $slider-b-fontFamily;
    font-size: $slider-b-fontSize;
    font-style: $slider-b-fontStyle;
    font-weight: $slider-b-fontBold;
    padding: 5px;
}



/*ACT Heart Divider Section*/
.act-heart-divider {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}


.slider-side-line {
    width: 50px;
    height: 8px;
    display: inline-block;
    border-top: solid 1px $slider-color-b-two;
    margin: 0px 5px 0px 5px;
}

.slider-heart-left {
    color: $slider-color-b-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
}

.slider-heart-right {
    color: $slider-color-b-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
    margin-left: -7px;
}

.slider-heart-center {
    color: $slider-color-b-one;
    margin-left: -7px;
    font-size: 18px !important;
    position: relative;
    z-index: 3;
    padding: 2px;
}
