/*My Story*/
.wed1-story {
    background-color: $story-color-a-one;
}
.tzourstory {
    padding: 69px 0;
}

.title_ourstory {
    font-size: $story-a-fontSize;
    font-style: $story-a-fontStyle;
    font-weight: $story-a-fontBold;
    font-family: $story-a-fontFamily;
    line-height: 54.4px;
    color: $story-color-b-one;
    position: relative;
    margin-top: 35px;
    text-align: center;
    letter-spacing: 3px;
}

.title_ourstory:after {
    width: 70px;
    height: 2px;
    background: $story-color-b-two;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -35px;
    content: '';
}

.ourstory_content {
    line-height: 24px;
    text-align: center;
    padding: 18px 0;
}

.ourstory_content p {
    font-size: $story-b-fontSize;
    font-style: $story-b-fontStyle;
    font-family: $story-b-fontFamily;
    font-weight: $story-b-fontBold;
    padding: 12px 0;
    margin: 0;
    line-height: 24px;
    color: $story-color-b-three;
    text-align: center;
    letter-spacing: 0.4px;
}
