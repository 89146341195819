
.ast-slide-content {
    text-align: center;
    background: $slider-color-a-one;
    max-width: 380px;
    height: 380px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 25%;
    margin: 80px auto auto;
    z-index: 1;
}

.ast-slide-animation {
    animation-delay: 0ms;
    animation-duration: 1000ms;
    pointer-events: all;
}

.ast-slide-animated-circle {
    width: 103%;
    height: 103%;
    background: $slider-color-a-one;
    opacity: 0.6;
    border-radius: 25%;
    position: absolute;
    left: -1.5%;
    top: -1.5%;
    animation-name: stretch;
    animation-duration: 1.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    z-index: -1;
}

@keyframes stretch {
    0% {
        transform: scale(.5);
    }

    50% {
    }

    100% {
        transform: scale(1.1);
    }
}

.ast-h1 {
    font-family: $slider-a-fontFamily;
    font-size: $slider-a-fontSize;
    font-style: $slider-a-fontStyle;
    font-weight: $slider-a-fontBold;
    color: $slider-color-a-three;
    padding: 20px;
}

.ast-date {
    font-family: $slider-c-fontFamily;
    font-family: $slider-c-fontFamily;
    font-size: $slider-c-fontSize;
    font-style: $slider-c-fontStyle;
    font-weight: $slider-c-fontBold;
    color: $slider-color-a-four;
}

.ast-cap-top-heading {
    font-family: $slider-b-fontFamily;
    font-size: $slider-b-fontSize;
    font-style: $slider-b-fontStyle;
    font-weight: $slider-b-fontBold;
    color: $slider-color-a-two;
    margin-bottom: 10px;
}

.carousel .carousel-inner .carousel-item img {
    height: 100vh;
    width: 100%;
}

.carousel-inner .carousel-item > img {
    -webkit-animation: thing 10s;
    -o-animation: thing 10s;
    animation: thing 10s;
}

@keyframes thing {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(1.2, 1.2);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.carousel-caption {
    bottom: 30% !important;
}
