/*My Story Section*/
.act-ourstory {
    padding: 69px 0;
}

.act-title_ourstory {
    line-height: 54.4px;
    font-size: $story-a-fontSize;
    font-style: $story-a-fontStyle;
    font-weight: $story-a-fontBold;
    font-family: $story-a-fontFamily;
    line-height: 54.4px;
    color: $story-color-b-one;
    margin: 0;
    text-align: center;
    letter-spacing: 3px;
    margin-top:30px;
}

.act-ourstory_content p {
    font-size: $story-b-fontSize;
    font-style: $story-b-fontStyle;
    font-weight: $story-b-fontBold;
    font-family: $story-b-fontFamily;
    color: $story-color-b-two;
    padding: 12px 0;
    margin: 0;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
}

/*ACT Heart Divider Section*/
.act-heart-divider {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}


.story-side-line {
    width: 50px;
    height: 8px;
    display: inline-block;
    border-top: solid 1px $story-color-c-two;
    margin: 0px 5px 0px 5px;
}

.story-heart-left {
    color: $story-color-c-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
}

.story-heart-right {
    color: $story-color-c-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
    margin-left: -7px;
}

.story-heart-center {
    color: $story-color-c-one;
    margin-left: -7px;
    font-size: 18px !important;
    position: relative;
    z-index: 3;
    padding: 2px;
}
