.cn2-schedule {
    background-color: $agenda-color-a-one;
}

.agenda-title {
    font-size: 38px;
    color: $agenda-color-b-one;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-family: "Poppins", cursive;
    margin-bottom: 45px
}

.agenda-btns {
    margin-bottom: 50px;
}

.agenda-btn {
    padding: 10px 30px;
    background: $agenda-color-a-two;
    color: $agenda-color-b-two;
    color: white;
    font-size: 13px;
    margin-right: 12px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.active {
    background-color: $agenda-color-a-four;
    color: $agenda-color-b-four;
}

.agenda-btn:hover {
    background: $agenda-color-a-three;
    color: $agenda-color-b-three;
}

.track-box {
   // border: 2px solid white;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.track-name {
    color: $agenda-color-b-one;
    font-weight: 700;
    font-family: $agenda-a-fontFamily;
}

.session-box {
    //border: 1px solid white;
    height: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.session-time {
    height: 92px;
    background-color: $agenda-color-c-one;
    border: 1px solid $agenda-color-c-one;
}

.session-time > p {
    color: $agenda-color-c-two;
    font-family: $agenda-b-fontFamily;
    font-size: 14px;
    font-weight: 700;
}

.session-details {
    height: 92px;
    background-color: $agenda-color-c-three;
    border: 1px solid $agenda-color-c-three;

    h5 {
        margin-top: 6px;
        color: $agenda-color-c-four;
        font-family: $agenda-c-fontFamily;
    }

    p {
        color: $agenda-color-c-four;
        margin: 4px;
        font-size: 11px;
        font-family: $agenda-c-fontFamily;
    }
}

.session-speaker {
    width: 70px;
    height: 70px;
    border-radius: 45px;
    margin: 6px;
    cursor: pointer;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
