/*Image Overlay--------*/



#container {
    .wedp-container {
        position: relative;
    }

    .image {
        display: block;
        width: 100%;
        height: auto;
    }

    .wedp-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: $portfolio-color-a-one;
    }

    .wedp-container:hover .wedp-overlay {
        opacity: 0.9;
    }

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .h {
        color: $portfolio-color-a-two;
        font-size: $portfolio-a-fontSize;
        font-style: $portfolio-a-fontStyle;
        font-weight: $portfolio-a-fontBold;
        font-family: $portfolio-a-fontFamily;
    }

    .w {
        color: $portfolio-color-a-three;
        font-size: $portfolio-b-fontSize;
        font-style: $portfolio-b-fontStyle;
        font-family: $portfolio-b-fontFamily;
        font-weight: $portfolio-b-fontBold;
    }
}
