
.map-section{
    background-color:$map-color-a-one;
}
.act-map-address {
    line-height: 30.8px;
    color: $map-color-b-one;
    letter-spacing: 2px;
    margin-top: 40px;
    font-size: $map-b-fontSize;
    font-style: $map-b-fontStyle;
    font-weight: $map-b-fontBold;
    font-family: $map-b-fontFamily;
}

.map-icon{
    margin-right:8px;
}

.address-text {
    color: $map-color-b-two;
    font-size: $map-c-fontSize;
    font-style: $map-c-fontStyle;
    font-weight: $map-c-fontBold;
    font-family: $map-c-fontFamily;
    margin-top: 20px;
}

