
/*footer Styles*/
.wed-social {
    background: $social-color-a-one;
    padding: 50px 0;
}

.social-title {
    color: $social-color-a-two;
    font-family: $social-a-fontFamily;
    font-size: $social-a-fontSize;
    font-style: $social-a-fontStyle;
    font-weight: $social-a-fontBold;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 45px
}

.tz_social {
    text-align: center;
    margin-top: 40px;
}

.tz_social a {
    display: inline-block;
    width: 70px;
    height: 70px;
    font-size: 25px;
    line-height: 70px;
    color: $social-color-b-two;
    text-align: center;
    border: 1px solid $social-color-b-one;
    background: $social-color-b-one;
    border-radius: 50%;
    margin: 8px 0 0 20px;
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
}

a {
    text-decoration: none !important;
}