#cnst-intro {
    width: 100%;
    height: 100vh;
    background: url('../imgs/intro-bg.jpg') top center;
    background-size: cover;
    overflow: hidden;
    position: relative;
}


#cnst-intro:before {
    content: "";
    background: rgba(6, 12, 34, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#cnst-intro .cnst-intro-container {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 90px;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
}

#cnst-intro h1 {
    color: $header-color-a-one;
    font-family: $header-a-fontFamily;
    font-size: $header-a-fontSize;
    font-style: $header-a-fontStyle;
    font-weight: $header-a-fontBold;
    text-transform: uppercase;
}


#cnst-intro p {
    color: $header-color-a-two;
    font-family: $header-b-fontFamily;
    font-weight: $header-b-fontBold;
    font-size: $header-b-fontSize;
    font-style: $header-b-fontStyle;
}

