
.our-ticket-pricing-table-area {
    background: url('../imgs/speaker-bg.jpg') top center;
    position: relative;
    z-index: 1;
}

.single-ticket-pricing-table {
    background-color: #ffffff;
    padding: 0 40px 40px 40px;
    border-radius: 10px;
    transition-duration: 500ms;
}

.single-ticket-pricing-table .ticket-plan {
    background-color: #df42b1;
    padding: 10px 25px;
    display: inline-block;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 0 0 5px 5px;
    margin-bottom: 25px;
    line-height: 1;
}

.single-ticket-pricing-table .ticket-icon img {
    max-height: 100px;
}

.single-ticket-pricing-table .ticket-price {
    font-size: 60px;
    color: #111343;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    margin-top: 25px;
    margin-bottom: 15px;
}

.single-ticket-pricing-table .ticket-pricing-table-details p {
    color: #5d5e8d;
    margin-bottom: 14px !important;
    line-height: 1.8 !important;
    font-size: 17px !important;
    font-weight: 400;
}

.single-ticket-pricing-table .ticket-pricing-table-details p i {
    color: #111343;
    margin-right: 5px;
}

.single-ticket-pricing-table:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.our-ticket-pricing-table-area .row .col-12 {
    padding-left: 30px;
    padding-right: 30px;
}

.pricing-confer-btn {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 24px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin-top: 40px;
    margin-bottom: 50px;
    color: #fff;
    animation-delay: 0.8s;
    background-image: -webkit-linear-gradient(120deg, #111343 0%, #505add 100%);
}

.pricing-confer-btn:hover {
    color: white;
}

.cnst-ptbl-row {
    margin-top: 50px !important;
}
