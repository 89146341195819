
/*-----Blog--*/
.act-blog {
    padding-top: 79px !important;
    padding-bottom: 64px !important;
    background-color: $blog-color-a-one;
}

.elementblog-title {
    display: block;
    font-size: $blog-a-fontSize;
    font-style: $blog-a-fontStyle;
    font-weight: $blog-a-fontBold;
    font-family: $blog-a-fontFamily;
    color: $blog-color-b-one;
    letter-spacing: 3px;
    line-height: 54.4px;
    margin: 0 0 65px;
    padding: 0 0 6px;
    position: relative;
    text-align: center;
}

.elementblog-title2 {
    margin: 25px 0 45px;
    padding: 0 0 8px;
}

.elementblog-title:after {
    background: $blog-color-b-two;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -35px;
    position: absolute;
    width: 70px;
}

.blog-item {
    margin-bottom: 30px;
}

.blog-item h3 {
    display: block;
    font-size: 14px !important;
    line-height: 24px;
    margin: 0;
    padding: 34px 0 10px;
    position: relative;
    color: #202020;
    letter-spacing: 3px;
}

.blog-item h3 a {
    text-decoration: none;
    color: $blog-color-c-one;
    font-size: $blog-b-fontSize;
    font-style: $blog-b-fontStyle;
    font-weight: $blog-b-fontBold;
    font-family: $blog-b-fontFamily;
    text-transform: uppercase;
}

.tzblog_meta {
    display: block;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    letter-spacing: 1px;
    color: $blog-color-c-two;
    text-transform: uppercase;
}

.tzblog_meta a {
    color: #bbbbbb;
    text-decoration: none;
}

.blog-item p {
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    padding: 10px 0 0;
    font-size: $blog-c-fontSize;
    font-style: $blog-c-fontStyle;
    font-weight: $blog-c-fontBold;
    font-family: $blog-c-fontFamily;
    color: $blog-color-c-three;
}

.blog-item .tzblog_readmore {
    font-size: 13px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    color: #d65579;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
}
