
/*Couple Section*/
#story {
    background: $couple-color-a-one;
}
.ast-h3 {
    color: $couple-color-a-two;
    font-family: $couple-a-fontFamily;
    font-size: $couple-a-fontSize;
    font-style: $couple-a-fontStyle;
    font-weight: $couple-a-fontBold;
    padding: 20px;
    text-align: center;
    margin-top: 70px;
}

.ast-h4 {
    color: $couple-color-b-two;
    font-family: $couple-b-fontFamily;
    font-size: $couple-b-fontSize;
    font-style: $couple-b-fontStyle;
    font-weight: $couple-b-fontBold;
    padding: 20px;
    text-align: center;
}

.ast-couple-left {
    text-align: center;
    padding: 40px;
}

.ast-couple-right {
    text-align: center;
    padding: 40px;
}

.ast-p-black {
    padding: 0 50px;
    text-align: center;
    margin-top: 20px;
    line-height: 26px;
    margin-bottom: 25px;
    color: $couple-color-a-three;
    font-family: $couple-c-fontFamily;
    font-size: $couple-c-fontSize;
    font-style: $couple-c-fontStyle;
    font-weight: $couple-c-fontBold;
}

.ast-p-content-black {
    padding: 0 50px;
    text-align: center;
    margin-top: 5px;
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 25px;
    color: $couple-color-b-three;
    font-family: $couple-c-fontFamily;
    font-size: $couple-c-fontSize;
    font-style: $couple-c-fontStyle;
    font-weight: $couple-c-fontBold;
}

.ast-couple-img {
    border: 10px solid $couple-color-b-one;
    width: 100%;
    height: auto;
}
