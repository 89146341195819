
/*CallAction Area before footer*/
.text-white{
    color:#fff !important;
}
.callaction-area {
    background: $newsletter-bg-img center;
    background-size: cover;
    text-align: center;
    color: #fff;
}

.callaction-area p {
    padding: 50px 87px;
    color: $newsletter-color-a-two;
    font-family: $newsletter-b-fontFamily;
}

.callaction-area h1 {
    font-size: 38px;
    font-weight: 700;
    letter-spacing: 0px;
    color: $newsletter-color-a-one;
    font-family: $newsletter-a-fontFamily;
}

.callaction-area .container {
    position: relative;
    z-index: 3;
}

.callaction-area .cn2-overlay-bg {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgb(6 12 34 / $newsLetter-bg-img-opacity );
    z-index: 2;
}

.callaction-btn {
    background-color: $newsletter-color-b-one;
    color: $newsletter-color-b-three;
    border: 1px solid transparent;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 300;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.callaction-btn:hover {
    background-color: transparent;
    border-color: $newsletter-color-b-two;
    color: $newsletter-color-b-two;
}