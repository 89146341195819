.our-sponsor-client-area {
    background-color: #000000;
    position: relative;
    z-index: 1;
}

.our-sponsor-area {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: 20px;
    padding: 40px;
}

.single-sponsor {
    border: 1px solid white;
    text-align: center;
}

.single-sponsor a img {
    opacity: 0.5;
    width: 150px;
    height: 120px;
    padding: 5%;
}

.single-sponsor a img:hover {
    opacity: 1;
}
