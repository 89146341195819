
.cn2-wedp {
    background-color: $wedparty-color-a-one;

}
.wedp-mb{
    margin-bottom:100px;
}
.wedp-title {
    color: $wedparty-color-a-two;
    font-family: $wedparty-a-fontFamily;
    font-size: $wedparty-a-fontSize;
    font-style: $wedparty-a-fontStyle;
    font-weight: $wedparty-a-fontBold;
    padding: 20px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 25px;
}

.wedp-btns {
    margin-bottom: 50px;
}

.wedp-btn {
    padding: 10px 30px;
    background: $wedparty-color-b-one;
    color: $wedparty-color-b-four;
    font-family: $wedparty-b-fontFamily;
    font-size: $wedparty-b-fontSize;
    font-style: $wedparty-b-fontStyle;
    font-weight: $wedparty-b-fontBold;
    margin-right: 12px;
    border: none;
    cursor: pointer;
    border-radius: 50px;
}

.active {
    background-color: $wedparty-color-b-two;
    color: $wedparty-color-b-four;
    border-radius: 50px;
}

.wedp-btn:hover {
    background: $wedparty-color-b-three;
    color: $wedparty-color-b-four;
    border-radius: 50px;
}


.image-area {
    padding: 30px;
}


.img-wrapper-detail {
    position: relative;
    text-align: center;
    display: block;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    background: $wedparty-color-c-two;
    -webkit-box-shadow: 0 1.5px 9px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 1.5px 9px 0 rgba(0,0,0,0.2);
    box-shadow: 0 1.5px 9px 0 rgb(0 0 0 / 20%);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 30px;
}

.img-wrapper-detail:hover {
    text-decoration: none;
    transform: translateY(-10px );
    cursor:pointer;
}


.img-wedp {
    width: 100%;
    height: 300px;
    overflow: hidden;
    display: block;
    margin-bottom: 20px;

    img {
        max-width: 100%;
        height: auto;
    }
}

.details-wedp {
    color: $wedparty-color-c-one;
    font-family: $wedparty-c-fontFamily;
    font-size: $wedparty-c-fontSize;
    font-style: $wedparty-c-fontStyle;
    font-weight: $wedparty-c-fontBold;
    display: block;
    margin-bottom: 22px;
    padding: 0 30px;
}
