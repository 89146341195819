.our-speaker-area {
    background: $speaker-bg-img top center;
    position: relative;
    z-index: 1;
}

.cn2-bg-gradient-overlay::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: $speaker-color-a-one;
    opacity: $speaker-bg-img-opacity;
}
.more-speaker-btn{
    margin-bottom:30px;
}

.confer-btn-white {
    //font-family: $speaker-font-family-primary;
    background: $speaker-color-c-one;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    transition: 0.5s;
    line-height: 1;
    margin-top: 40px;
    color: $speaker-color-c-three;
}

.confer-btn-white:hover {
    background: $speaker-color-c-two;
    border: 0px;
}

.image-area {
    padding: 30px;
}

.img-wrapper {
    position: relative;
    overflow: hidden;
}

.img-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 180%;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,.3);
    z-index: 1;
    transform: skew(45deg);
    transition: .5s;
}

.img-wrapper:hover:before {
    left: -180%;
}

.img-wrapper img {
    max-width: 100%;
    height: auto;
}

.img-wrapper:hover img {
    filter: grayscale(0%);
    transform: scale(1.1);
}

.img-wrapper h2 {
    background: $speaker-color-b-two;
    color: $speaker-color-b-one;
    font-family: $speaker-c-fontFamily;
    text-align: center;
    font-size: 19px;
    letter-spacing: 1px;
    font-weight: 600;
    margin: 0;
    padding: 20px 0;
    bottom: 0;
    width: 100%;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: right;
    transition: 1s;
}

.img-wrapper:hover h2 {
    transform: perspective(400px) rotateY(0deg);
}

.cnst-speaker-p {
    font-size: 14px;
    font-weight: 500;
    color: $speaker-color-a-three;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 5px;
    display: inline-block;
    margin-bottom: 25px;
    font-family: $speaker-b-fontFamily;
}

.cnst-h3 {
    font-size: 40px;
    color: $speaker-color-a-two;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: $speaker-a-fontFamily;
}


#speakers .speaker {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 30px;
}

#speakers .speaker .details {
    background-color: $speaker-color-b-one;
    background: $speaker-color-b-two;
    position: absolute;
    left: 0;
    bottom: -72px;
    right: 0;
    text-align: center;
    padding-top: 10px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

#speakers .speaker .details h3 {
    color: $speaker-color-b-one;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

#speakers .speaker .details p {
    color: $speaker-color-b-one;
    font-size: 15px;
    margin-bottom: 10px;
}

#speakers .speaker .details .social {
    height: 40px;
}

#speakers .speaker .details a {
    color: $speaker-color-b-one;
    font-weight: 700;
}

#speakers .speaker .details a:hover {
    color: white;
}

#speakers .speaker:hover .details {
    bottom: 0;
}

#speakers-details {
    padding: 60px 0;
}

#speakers-details .details h2 {
    color: #112363;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

#speakers-details .details .social {
    margin-bottom: 30px;
    padding: 20px;
}

#speakers-details .details .social a {
    background: orange;
    color: #112363;
    line-height: 1;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding-top: 9px;
}

#speakers-details .details .social a i {
    font-size: 18px;
}

#speakers-details .details p {
    color: #112363;
    font-size: 15px;
    margin-bottom: 10px;
}

.speaker-social {
    color: $speaker-color-b-four;
    font-weight: 700;
    padding: 4px;
    background: $speaker-color-b-three;
    margin: 3px;
    cursor: pointer;
}

.speaker-social > i {
    width: 21px;
    color: $speaker-color-b-four;
}