/*Gallery Section*/
#gallery {
    background: $gallery-color-a-one;
}
.grid {
    float: left;
    padding: 0 7.5px 15px;
    width: 33.33%;
}

.thumbnail > img {
    max-width: 100%;
    border-radius: 15%;
}

.act-gallery {
    margin-top: 100px;
}

.act-gallery-pad {
    padding-top: 30px;
}

.act-title_gallery {
    line-height: 54.4px;
    font-size: $gallery-a-fontSize;
    font-style: $gallery-a-fontStyle;
    font-weight: $gallery-a-fontBold;
    font-family: $gallery-a-fontFamily;
    line-height: 54.4px;
    color: $gallery-color-a-two;
    margin: 0;
    text-align: center;
    letter-spacing: 3px;
}

/*ACT Heart Divider Section*/
.act-heart-divider {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}


.gallery-side-line {
    width: 50px;
    height: 8px;
    display: inline-block;
    border-top: solid 1px $gallery-color-b-two;
    margin: 0px 5px 0px 5px;
}

.gallery-heart-left {
    color: $gallery-color-b-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
}

.gallery-heart-right {
    color: $gallery-color-b-two;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
    margin-left: -7px;
}

.gallery-heart-center {
    color: $gallery-color-b-one;
    margin-left: -7px;
    font-size: 18px !important;
    position: relative;
    z-index: 3;
    padding: 2px;
}
