.homepage-info-section .container {
    padding: 90px 30px;
}
.homepage-info-section{
    background-color:$about-color-a-one;
}
.homepage-info-section figure img {
    max-width: 100%;
}

.homepage-info-section h2 {
    font-family: $about-a-fontFamily;
    color: $about-color-b-one;
    line-height: 1.2em !important;
    margin-bottom: 30px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 32px;
}

.homepage-info-section p {
    font-size: 18px;
    font-family:$about-b-fontFamily;
    line-height: 1.7;
    color: $about-color-b-two;
}

.cn2-btn1 {
    padding: 18px 50px;
    background: $about-color-c-one;
    color: $about-color-b-three;
    font-family: $about-c-fontFamily;
    font-size: 14px;
    margin-right: 35px;
    font-weight: 600;
}

.cn2-btn2 {
    padding: 18px 50px;
    background: $about-color-c-three;
    color: $about-color-b-four;
    font-family: $about-c-fontFamily;
    font-size: 14px;
    margin-right: 35px;
    font-weight: 600;
}

.cn2-btn1:hover {
    background:$about-color-c-two;
}

.cn2-btn2:hover {
    background: $about-color-c-four;
}
