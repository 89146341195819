.footer-area {
    padding-top: 100px;
    background-color: $footer-color-a-one;
}

h6 {
    margin-bottom: 25px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $footer-color-a-two;
    font-family: $footer-a-fontFamily;
}

.copy-right-text i, .copy-right-text a {
    color: $footer-color-b-one;
}

.footer-social a {
    padding-right: 25px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.footer-social a:hover i {
    color: $footer-color-b-one;
}

.footer-social i:hover {
    color: $footer-color-b-two !important;
}


.footer-social i {
    color: $footer-color-b-one;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

@media (max-width: 991.98px) {
    .footer-social {
        text-align: left
    }
}

.single-footer-widget input {
    display: inline;
    border: none;
    width: 80% !important;
    font-weight: 300;
    background: #fff;
    color: #eee;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px;
    padding: 10px 18px
}

.single-footer-widget input:focus {
    background-color: #fff
}

.single-footer-widget p {
    margin-bottom: 15px;
    color: $footer-color-a-three;
    font-family: $footer-b-fontFamily;
}

.footer-text-copyright {
    margin-bottom: 15px;
    margin-top: 15px;
    color: $footer-color-b-three !important;
    font-family: $footer-c-fontFamily !important;
}


.single-footer-widget .bb-btn {
    //color: $color-secondaryDark;
    font-weight: 300;
    border-radius: 0;
    z-index: 9999;
    cursor: pointer
}

.single-footer-widget .info {
    position: absolute;
    margin-top: 20%;
    color: #fff;
    font-size: 12px
}

.single-footer-widget .info.valid {
    color: green
}

.single-footer-widget .info.error {
    color: red
}

.single-footer-widget .click-btn {
    background-color: $footer-color-b-four;
    color: #fff;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 9px 12px;
    border: 0;
    margin-top: -3px;
}

.single-footer-widget .click-btn:hover {
    background-color: #FFFFFF;
    color: $footer-color-b-four !important;
    border: 1px solid $footer-color-b-four;
}


@media (max-width: 991.98px) {
    .single-footer-widget {
        margin-bottom: 30px !important;
    }
}

@media (max-width: 800px) {
    .social-widget {
        margin-top: 30px;
    }
}

.footer-text {
    padding-top: 20px !important;
}

.footer-text a, .footer-text i {
    //color: $color-secondary;
}

.whole-wrap {
    background-color: #fff
}

.cn2-footer-input-abs {
    position: absolute;
    left: -5000px;
}
