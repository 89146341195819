.left-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 12px;
    z-index: 1;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    border-right: 15px solid $agenda-color-c-three;
    left: -15px;
}



.cn2-schedule {
    background-color: $agenda-color-a-one;
}

.agenda-mb{
    margin-bottom:100px;
}
.agenda-title {
    color: $agenda-color-b-one;
    font-family: Gabriola;
    font-size: 48px;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 25px;
}

.agenda-btns {
    margin-bottom: 50px;
}

.agenda-btn {
    padding: 10px 30px;
    background: $agenda-color-a-two;
    color: $agenda-color-b-two;
    font-size: 13px;
    margin-right: 12px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    font-family: Comic Sans Ms;
}

.agenda-active-btn {
    background-color: $agenda-color-a-four;
    color: $agenda-color-b-four;
    border-radius: 50px;
}

.agenda-btn:hover {
    background: $agenda-color-a-three;
    color: $agenda-color-b-three;
    border-radius: 50px;
}

.track-box {
    // border: 2px solid white;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 25px;
    position: relative;
}
.timeline {
    position: relative;
}
.timeline::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 90%;
    margin-top: 10%;
    background: $agenda-color-c-one;
    top: 0;
    left: 0;
    margin-left: -3px;
}
.track-name {
    color: $agenda-color-b-one;
    font-family: $agenda-a-fontFamily;
    font-size: $agenda-a-fontSize;
    font-style: $agenda-a-fontStyle;
    font-weight: $agenda-a-fontBold;
}

.session-box {
    //border: 1px solid white;
    height: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
}
.side-img {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    right: 378px;
    top: 7px;
    z-index: 10;
    background: $agenda-color-c-three;
}
.side-img-text {
    color: $agenda-color-c-four;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size:20px;
}

@media screen and (max-width: 1400px) {
    .side-img {
        right: 109%;
    }
}

@media screen and (max-width: 1200px) {
    .side-img {
        right: 112%;
    }
}

@media screen and (max-width: 1000px) {
    .side-img {
        right: 110%;
    }
}

@media screen and (max-width: 800px) {
    .side-img {
        right: 106%;
    }
}
@media screen and (max-width: 800px) {
    .side-img {
        right: 106%;
    }
    .timeline {
        margin: 24px;
    }
}

@media screen and (max-width: 550px) {
    .side-img {
        right: 106%;
    }

    .timeline {
        margin: 24px;
    }
}
@media screen and (max-width: 500px) {
    .side-img {
        right: 107%;
    }

    .timeline {
        margin: 24px;
    }
}

@media screen and (max-width: 450px) {
    .side-img {
        right: 108%;
    }

    .timeline {
        margin: 24px;
    }
}


@media screen and (max-width: 400px) {
    .side-img {
        right: 109%;
    }

    .timeline {
        margin: 24px;
    }
}

@media screen and (max-width: 370px) {
    .side-img {
        right: 110%;
    }

    .timeline {
        margin: 24px;
    }
}

@media screen and (max-width: 340px) {
    .side-img {
        right: 112%;
    }

    .timeline {
        margin: 24px;
    }
}

.session-time {
    height: 92px;
    background-color: $agenda-color-c-one;
    border: 1px solid $agenda-color-c-one;
    border-radius: 15px 0px 0px 15px;
}

.session-time > p {
    color: $agenda-color-c-two;
    font-family: $agenda-b-fontFamily;
    font-size: $agenda-b-fontSize;
    font-style: $agenda-b-fontStyle;
    font-weight: $agenda-b-fontBold;
}

.session-details {
    border-radius: 0px 15px 15px 0px;
    height: 92px;
    background-color: $agenda-color-c-three;
    border: 1px solid $agenda-color-c-three;


    h5 {
        margin-top: 6px;
        color: $agenda-color-c-four;
        font-family: $agenda-c-fontFamily;
        font-size: $agenda-c-fontSize;
        font-style: $agenda-c-fontStyle;
        font-weight: $agenda-c-fontBold;
    }

    p {
        font-size: $agenda-c-fontSize;
        font-style: $agenda-c-fontStyle;
        font-weight: $agenda-c-fontBold;
        color: $agenda-color-c-four;
        margin: 4px;
        font-family: $agenda-c-fontFamily;
    }
}

.session-speaker {
    width: 70px;
    height: 70px;
    border-radius: 45px;
    margin: 6px;
    cursor: pointer;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
