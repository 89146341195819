.our-speaker-area {
    background: url('../imgs/speaker-bg.jpg') top center;
    position: relative;
    z-index: 1;
}

.bg-gradient-overlay::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #c63fa4;
    background-image: -webkit-linear-gradient(120deg, $color-secondary 0%, #505add 100%);
    opacity: 0.9;
}

.confer-btn-white {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin-top: 40px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 1px solid white;
}

.confer-btn-white:hover {
    background-image: -webkit-linear-gradient(120deg, #505add 0%, $color-secondary 100%);
    color: #fff;
    border: 0px;
}

.cnst-speaker-p {
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 5px;
    display: inline-block;
}


#speakers {
    padding: 60px 0 30px 0;
}

#speakers .speaker {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 30px;
}

#speakers .speaker .details {
    background-color: transparent;
    background-image: -webkit-linear-gradient(180deg, #313dd1cc 0%, #5ed4c3 100%);
    position: absolute;
    left: 0;
    bottom: -72px;
    right: 0;
    text-align: center;
    padding-top: 10px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

#speakers .speaker .details h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

#speakers .speaker .details p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
}

#speakers .speaker .details .social {
    height: 40px;
}

#speakers .speaker .details a {
    color: #fff;
    font-weight: 700;
}

#speakers .speaker .details a:hover {
    color: white;
}

#speakers .speaker:hover .details {
    bottom: 0;
}

#speakers-details {
    padding: 60px 0;
}

#speakers-details .details h2 {
    color: #112363;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

#speakers-details .details .social {
    margin-bottom: 30px;
    padding: 20px;
}

#speakers-details .details .social a {
    background: $color-third;
    color: #112363;
    line-height: 1;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding-top: 9px;
}

#speakers-details .details .social a:hover {
    background: black;
    color: #fff;
}

#speakers-details .details .social a i {
    font-size: 18px;
}

#speakers-details .details p {
    color: #112363;
    font-size: 15px;
    margin-bottom: 10px;
}
