
.cn2-wedp{
    background-color: $wedparty-color-a-one;
}

.wedp-title {
    color: $wedparty-color-a-two;
    font-family: $wedparty-a-fontFamily;
    font-size: $wedparty-a-fontSize;
    font-style: $wedparty-a-fontStyle;
    font-weight: $wedparty-a-fontBold;
    line-height: 54.4px;
    margin: 0;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
}

.wedp-btns {
    margin-bottom: 50px;
}

.wedp-btn {
    padding: 10px 30px;
    background: $wedparty-color-b-one;
    color: $wedparty-color-b-four;
    font-family: $wedparty-b-fontFamily;
    font-size: $wedparty-b-fontSize;
    font-style: $wedparty-b-fontStyle;
    font-weight: $wedparty-b-fontBold;
    margin-right: 12px;
    border: none;
    cursor: pointer;
}

.active {
    background-color: $wedparty-color-b-two;
    color: $wedparty-color-b-four;
}

.wedp-btn:hover {
    background: $wedparty-color-b-three;
    color: $wedparty-color-b-four;
}


.image-area {
    padding: 30px;
}

.img-wrapper {
    position: relative;
    overflow: hidden;
}

.img-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 180%;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,.3);
    z-index: 1;
    transform: skew(45deg);
    transition: .5s;
}

.img-wrapper:hover:before {
    left: -180%;
}

.img-wrapper img {
    max-width: 100%;
    height: auto;
    border-radius:15%;
}

.img-wrapper:hover img {
    filter: grayscale(0%);
    transform: scale(1.1);
}


.cnst-speaker-p {
    font-size: 14px;
    font-weight: 500;
    color: $wedparty-color-c-one;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 5px;
    display: inline-block;
    margin-bottom: 25px;
    font-family: $wedparty-b-fontFamily;
}

.cnst-h3 {
    font-size: 40px;
    color: $wedparty-color-a-two;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: $wedparty-a-fontFamily;
}


 .speaker {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 30px;
}

 .speaker .details {
    background-color: $wedparty-color-c-two;
    position: absolute;
    left: 0;
    bottom: -72px;
    right: 0;
    text-align: center;
    padding-top: 10px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

 .speaker .details h3 {
    color: $wedparty-color-b-one;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

.speaker .details p {
    color: $wedparty-color-c-one;
    font-family: $wedparty-c-fontFamily;
    font-size: $wedparty-c-fontSize;
    font-style: $wedparty-c-fontStyle;
    font-weight: $wedparty-c-fontBold;
    margin-bottom: 45px;
}

 .speaker .details .social {
    height: 40px;
}

.speaker .details a {
    color: $wedparty-color-c-one;
    font-family: $wedparty-c-fontFamily;
    font-size: $wedparty-c-fontSize;
    font-style: $wedparty-c-fontStyle;
    font-weight: $wedparty-c-fontBold;
}

 .speaker .details a:hover {
    color: white;
}

 .speaker:hover .details {
    bottom: 0;
}


.speaker-social {
    color: $wedparty-color-c-four;
    font-size:14px !important;
    font-weight: 700;
    padding: 4px;
    background: $wedparty-color-c-three;
    margin: 3px;
    cursor: pointer;
}

.speaker-social > i {
    width: 21px;
    color: $wedparty-color-c-four;
}


/*ACT Heart Divider Section*/
.act-heart-divider {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}


.wedparty-side-line {
    width: 50px;
    height: 8px;
    display: inline-block;
    border-top: solid 1px $wedparty-color-a-four;
    margin: 0px 5px 0px 5px;
}

.wedparty-heart-left {
    color: $wedparty-color-a-four;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
}

.wedparty-heart-right {
    color: $wedparty-color-a-four;
    font-size: 18px !important;
    position: relative;
    z-index: 2;
    padding: 2px;
    margin-left: -7px;
}

.wedparty-heart-center {
    color: $wedparty-color-a-three;
    margin-left: -7px;
    font-size: 18px !important;
    position: relative;
    z-index: 3;
    padding: 2px;
}
