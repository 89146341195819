/*Gallery Section*/
.wed3-gallery{
    background: $gallery-color-a-one;
}

.wed3-gallery-h3 {
    color: $gallery-color-a-two;
    font-family: $gallery-a-fontFamily;
    font-size: $gallery-a-fontSize;
    font-style: $gallery-a-fontStyle;
    font-weight: $gallery-a-fontBold;
    padding: 20px;
    text-align: center;
    margin-top: 40px;
}
.grid {
    float: left;
    padding: 0 7.5px 15px;
    width: 33.33%;
}

.thumbnail > img {
    max-width: 100%;
}

.act-gallery {
    margin-top: 70px;
    padding-bottom: 130px;
}

.act-gallery-pad {
    padding-top: 30px;
}
