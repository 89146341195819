/*-counter-section---------*/
.std {
    background-color: $std-color-a-one;
    padding: 130px 0 115px;
}

.act-std-text {
    line-height: 30.8px;
    color: $std-color-b-one;
    letter-spacing: 2px;
    margin-top: 10px;
    font-size: $std-a-fontSize;
    font-style: $std-a-fontStyle;
    font-weight: $std-a-fontBold;
    font-family: $std-a-fontFamily;
}

.std-date {
    line-height: 30.8px;
    color: $std-color-b-two;
    letter-spacing: 2px;
    font-size: 18px;
    font-style: normal;
    font-size: $std-b-fontSize;
    font-style: $std-b-fontStyle;
    font-weight: $std-b-fontBold;
    font-family: $std-b-fontFamily;
}

.std-address {
    line-height: 30.8px;
    color: $std-color-b-three;
    letter-spacing: 2px;
    margin-top: 4px;
    font-size: 18px;
    font-style: normal;
    font-size: $std-c-fontSize;
    font-style: $std-c-fontStyle;
    font-weight: $std-c-fontBold;
    font-family: $std-c-fontFamily;
}

.std-calendar-icon {
    color: $std-color-b-one;
    font-size: 42px !important;
    line-height: 50.4px;
    margin: 0;
    display: block;
    float: left;
}

