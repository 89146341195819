#cn2-intro {
    width: 100%;
    height: 100vh;
    background: $header-bg-img top center;
    background-size: cover;
    overflow: hidden;
    position: relative;
}


#cn2-intro:before {
    content: "";
    background: rgb(6 12 34 / $header-bg-img-opacity );
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#cn2-intro .cn2-intro-container {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 90px;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
}

#cn2-intro h1 {
    color: $header-color-a-one;
    font-family: $header-a-fontFamily;
    font-size: $header-a-fontSize;
    font-style: $header-a-fontStyle;
    font-weight: 700;
}

#cn2-intro p {
    color: $header-color-a-two;
    font-family: $header-b-fontFamily;
    font-weight: 400;
    font-size: $header-b-fontSize;
    font-style: $header-b-fontStyle;
}

.cn2-timer {
    font-size: $header-b-fontSize;
    font-style: $header-b-fontStyle;
    font-weight: 600;
    color: $header-color-a-two;
    font-family: $header-b-fontFamily;
    padding-bottom: 15px;
}

#cn2-intro h4 {
    color: $header-color-a-two;
    font-family: $header-b-fontFamily;
    margin-bottom: 6px;
    font-weight: 300;
    font-size: $header-b-fontSize;
    font-style: $header-b-fontStyle;
}
#cn2-intro h4 span {
    color: $header-color-a-two;
    font-family: $header-b-fontFamily;
    margin-right: 5px;
    font-size: $header-b-fontSize;
    font-style: $header-b-fontStyle;
}