
.carousel .carousel-inner .carousel-item img {

    width: 100%;
}

.carousel-inner .carousel-item > img {
    -webkit-animation: thing 30s;
    -o-animation: thing 30s;
    animation: thing 30s;
}


.act-and {
    color: #d65679;
    font-size: 100px !important;
}

@keyframes thing {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(1.5, 1.5);
    }
}

.act-cap {
    font-family: $slider-a-fontFamily;
    font-size: $slider-a-fontSize;
    font-style: $slider-a-fontStyle;
    font-weight: $slider-a-fontBold;
    color: $slider-color-a-one;
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.carousel-caption {
    bottom: 45% !important;
}
