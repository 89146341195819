
/*Media Quries*/

@media screen and (max-width: 700px) {
    .cnst-h3 {
        font-size: 25px;
        margin-bottom: 0px;
    }

    #cnst-intro h1 {
        font-size: 30px;
    }

    #cnst-intro p {
        font-size: 15px;
    }

    .cnst-p {
        margin-top: 25px;
    }

    .confer-btn {
        margin-bottom: 45px;
    }

    .cnst-h4 {
        font-size: 18px;
        margin-bottom: 25px;
    }

    .single-ticket-pricing-table {
        margin-bottom: 30px;
    }
}

@media screen and (min-width : 990px) and (max-width: 1200px) {
    .cnst-navlink {
        font-size: 11px !important;
        padding: 28px 19px 15px !important;
    }

    .cnst-navbar-navShrink {
        padding-left: 50px !important;
    }

    .cnst-navbar-nav {
        padding-left: 50px !important;
    }
}

@media screen and (min-width : 1201px) and (max-width: 1250px) {
    .cnst-navlink {
        font-size: 11px !important;
        padding: 28px 19px 15px !important;
    }

    .cnst-navbar-navShrink {
        padding-left: 90px !important;
    }

    .cnst-navbar-nav {
        padding-left: 60px !important;
    }
}

@media screen and (min-width : 1251px) and (max-width: 1400px) {
    .cnst-navlink {
        font-size: 11px !important;
        padding: 28px 19px 15px !important;
    }

    .cnst-navbar-navShrink {
        padding-left: 110px !important;
    }

    .cnst-navbar-nav {
        padding-left: 80px !important;
    }
}
