.act-header {
    background: $navbar-color-a-one;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
}

.act-header1 {
    background: $navbar-color-a-one;
    padding: 0;
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
    opacity: 0.8;
}

.act-logoBrand {
    display: inline-block;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    vertical-align: middle;
    text-align: center;
}

.navbar-nav .nav-link {
    font-size: 13px;
    line-height: 38.4px;
    color: $navbar-color-b-one;
    padding: 18px !important;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    display:flex;
    font-family: $navbar-a-fontFamily;
    font-size: $navbar-a-fontSize;
    font-style: $navbar-a-fontStyle;
    font-weight: $navbar-a-fontBold;
}

.navbar-toggler {
    background-color: orange !important;
    color: white !important;
    border-radius: 0px !important;
    margin-top: 11px;
}

button.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

#offcanvasNavbar-expand-lg {
    background-color: rgb(0 0 0 / 90%);
}

.offcanvas-title {
    font-weight: 700 !important;
    color: white;
    padding: 10px;
}

.btn-close {
    opacity: 2.5 !important;
}


.navbar-nav .nav-link:hover {
    color: $navbar-color-b-two !important;
}

.navbar-nav .nav-link.active {
    color: white !important;
}

.act-search-icon {
    font-size: 18px !important;
}

.act-navbar-nav {
    text-align:center;
}

.act-navbar-navShrink {
    padding: 0px 0px;
}
