
/*Media Quries*/


@media screen and (max-width: 1000px) {
    
    .cn2-navLogo {
        width: 180px;
        height: 42px;
    }

    .offcanvas-header > .cn2-mobileLogo {
        width: 180px;
        height: 42px;
    }

    .cn2-navbar-nav {
        color: white !important;
    }

    .cn2-offcanvas {
        background-color: greenyellow !important;
    }

    #cn2-intro {
        h1 {
            font-size: 30px;
        }

        h4 {
            font-size: 14px;
        }

        p {
            font-size: 16px;
        }

        .cn2-timer {
            font-size: 18px;
        }
    }

    .homepage-info-section {
        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }

        .cn2-btn1 {
            padding: 12px 12px;
            font-size: 12px;
            margin-right: 12px;
        }

        .cn2-btn2 {
            padding: 12px 12px;
            font-size: 12px;
            margin-right: 12px;
        }
    }

    header {
        border-bottom: 0px solid rgba(31, 35, 44, 0.12);
    }

    .price-area h1 {
        font-size: 18px;
    }

    .menu-content p {
        font-size: 12px;
    }

    .callaction-area h1 {
        font-size: 18px;
    }

    .callaction-btn {
        padding: 10px 25px;
        font-size: 12px;
    }

    .callaction-area p {
        padding: 40px 8px;
        font-size: 12px;
    }

    .cnst-h3 {
        font-size: 18px;
    }

    .cnst-speaker-p {
        font-size: 12px;
    }
}
