/* ------------------------------------------
[Master Stylesheet]
--------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Audiowide|Sofia|Trirong|Montserrat|Roboto|Bitter');

// Stylesheet

@import "variables";

$navbar-color-a-one: var(--NavData-color-a-one);
$navbar-color-b-one: var(--NavData-color-b-one);
$navbar-color-b-two: var(--NavData-color-b-two);
$navbar-color-c-one: var(--NavData-color-c-one);
$navbar-color-c-two: var(--NavData-color-c-two);
$navbar-color-c-three: var(--NavData-color-c-three);
$navbar-a-fontFamily: var(--NavData-a-fontFamily);
$navbar-a-fontSize: var(--NavData-a-fontSize);
$navbar-a-fontStyle: var(--NavData-a-fontStyle);
$navbar-a-fontBold: var(--NavData-a-fontBold);

$about-color-a-one: var(--AboutData-color-a-one);
$about-color-b-one: var(--AboutData-color-b-one);
$about-color-b-two: var(--AboutData-color-b-two);
$about-color-b-three: var(--AboutData-color-b-three);
$about-color-b-four: var(--AboutData-color-b-four);
$about-color-c-one: var(--AboutData-color-c-one);
$about-color-c-two: var(--AboutData-color-c-two);
$about-color-c-three: var(--AboutData-color-c-three);
$about-color-c-four: var(--AboutData-color-c-four);
$about-a-fontFamily: var(--AboutData-a-fontFamily);
$about-b-fontFamily: var(--AboutData-b-fontFamily);
$about-c-fontFamily: var(--AboutData-c-fontFamily);

$header-bg-img: var(--HeaderData-bg-img);
$header-bg-img-opacity: var(--HeaderData-bg-img-opacity);
$header-color-a-one: var(--HeaderData-color-a-one);
$header-color-a-two: var(--HeaderData-color-a-two);
$header-a-fontFamily: var(--HeaderData-a-fontFamily);
$header-a-fontSize: var(--HeaderData-a-fontSize);
$header-a-fontStyle: var(--HeaderData-a-fontStyle);
$header-b-fontFamily: var(--HeaderData-b-fontFamily);
$header-b-fontSize: var(--HeaderData-b-fontSize);
$header-b-fontStyle: var(--HeaderData-b-fontStyle);

$speaker-bg-img: var(--SpeakerData-bg-img);
$speaker-bg-img-opacity: var(--SpeakerData-bg-img-opacity);
$speaker-color-a-one: var(--SpeakerData-color-a-one);
$speaker-color-a-two: var(--SpeakerData-color-a-two);
$speaker-color-a-three: var(--SpeakerData-color-a-three);
$speaker-color-b-one: var(--SpeakerData-color-b-one);
$speaker-color-b-two: var(--SpeakerData-color-b-two);
$speaker-color-b-three: var(--SpeakerData-color-b-three);
$speaker-color-b-four: var(--SpeakerData-color-b-four);
$speaker-color-c-one: var(--SpeakerData-color-c-one);
$speaker-color-c-two: var(--SpeakerData-color-c-two);
$speaker-color-c-three: var(--SpeakerData-color-c-three);
$speaker-a-fontFamily: var(--SpeakerData-a-fontFamily);
$speaker-b-fontFamily: var(--SpeakerData-b-fontFamily);
$speaker-c-fontFamily: var(--SpeakerData-c-fontFamily);

$sponsor-color-a-one: var(--SponsorData-color-a-one);

$agenda-color-a-one: var(--AgendaData-color-a-one);
$agenda-color-a-two: var(--AgendaData-color-a-two);
$agenda-color-a-three: var(--AgendaData-color-a-three);
$agenda-color-a-four: var(--AgendaData-color-a-four);
$agenda-color-b-one: var(--AgendaData-color-b-one);
$agenda-color-b-two: var(--AgendaData-color-b-two);
$agenda-color-b-three: var(--AgendaData-color-b-three);
$agenda-color-b-four: var(--AgendaData-color-b-four);
$agenda-color-c-one: var(--AgendaData-color-c-one);
$agenda-color-c-two: var(--AgendaData-color-c-two);
$agenda-color-c-three: var(--AgendaData-color-c-three);
$agenda-color-c-four: var(--AgendaData-color-c-four);
$agenda-a-fontFamily: var(--AgendaData-a-fontFamily);
$agenda-b-fontFamily: var(--AgendaData-b-fontFamily);
$agenda-c-fontFamily: var(--AgendaData-c-fontFamily);

$pricing-color-a-one: var(--PricingData-color-a-one);
$pricing-color-a-two: var(--PricingData-color-a-two);
$pricing-color-a-three: var(--PricingData-color-a-three);
$pricing-color-b-one: var(--PricingData-color-b-one);
$pricing-color-b-two: var(--PricingData-color-b-two);
$pricing-color-b-three: var(--PricingData-color-b-three);
$pricing-color-b-four: var(--PricingData-color-b-four);
$pricing-color-c-one: var(--PricingData-color-c-one);
$pricing-color-c-two: var(--PricingData-color-c-two);
$pricing-color-c-three: var(--PricingData-color-c-three);
$pricing-color-c-four: var(--PricingData-color-c-four);
$pricing-a-fontFamily: var(--PricingData-a-fontFamily);
$pricing-b-fontFamily: var(--PricingData-b-fontFamily);
$pricing-c-fontFamily: var(--PricingData-c-fontFamily);

$newsletter-bg-img: var(--NewsLetterData-bg-img);
$newsLetter-bg-img-opacity: var(--NewsLetterData-bg-img-opacity);
$newsletter-color-a-one: var(--NewsLetterData-color-a-one);
$newsletter-color-a-two: var(--NewsLetterData-color-a-two);
$newsletter-color-b-one: var(--NewsLetterData-color-b-one);
$newsletter-color-b-two: var(--NewsLetterData-color-b-two);
$newsletter-color-b-three: var(--NewsLetterData-color-b-three);
$newsletter-a-fontFamily: var(--NewsLetterData-a-fontFamily);
$newsletter-b-fontFamily: var(--NewsLetterData-b-fontFamily);

$footer-color-a-one: var(--FooterData-color-a-one);
$footer-color-a-two: var(--FooterData-color-a-two);
$footer-color-a-three: var(--FooterData-color-a-three);
$footer-color-b-one: var(--FooterData-color-b-one);
$footer-color-b-two: var(--FooterData-color-b-two);
$footer-color-b-three: var(--FooterData-color-b-three);
$footer-color-b-four: var(--FooterData-color-b-four);
$footer-a-fontFamily: var(--FooterData-a-fontFamily);
$footer-b-fontFamily: var(--FooterData-b-fontFamily);
$footer-c-fontFamily: var(--FooterData-c-fontFamily);

$map-color-a-one: var(--MapData-color-a-one);
$map-color-b-one: var(--MapData-color-b-one);
$map-color-b-two: var(--MapData-color-b-two);
$map-c-fontFamily: var(--MapData-c-fontFamily);
$map-c-fontSize: var(--MapData-c-fontSize);
$map-c-fontStyle: var(--MapData-c-fontStyle);
$map-c-fontBold: var(--MapData-c-fontBold);
$map-b-fontFamily: var(--MapData-b-fontFamily);
$map-b-fontSize: var(--MapData-b-fontSize);
$map-b-fontStyle: var(--MapData-b-fontStyle);
$map-b-fontBold: var(--MapData-b-fontBold);

@import "setting";

.act-Conf-2 {

    @import "header";
    @import "intro";
    @import "pricing";
    @import "sponsor";
    @import "newsletter";
    @import "footer";
    @import "about";
    @import "speaker";
    @import "slider";
    @import "mediaquries";
    @import "schedule";
    @import "map";
    /*

@import "speaker";
@import "sponser";
@import "contact";
@import "mediaquries";*/
}

div.section-edit-border:hover {
    border-color: red;
    border-width: 1px;
    border-style: solid;
    position: relative;
}

div.section-edit-border .section-edit-button {
    display: block;
    position: absolute;
    bottom: 0%;
    right: 0%;
    /*    transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);*/
    background-color: #555;
    color: red;
    font-size: 16px;
    padding: 2px 14px;
    border: none;
    cursor: pointer;
    border-radius: 0px;
    z-index: 10040;
}

div.section-edit-border .section-edit-button:hover {
    background-color: yellow;
}

div.topright {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 18px;
}
