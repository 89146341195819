/* ------------------------------------------
[Master Stylesheet]
--------------------------------------------- */

// Stylesheet
#act-Wed-2 {
    @import "variables";

    $navbar-color-a-one: var(--NavData-color-a-one);
    $navbar-color-b-one: var(--NavData-color-b-one);
    $navbar-color-b-two: var(--NavData-color-b-two);
    $navbar-a-fontFamily: var(--NavData-a-fontFamily);
    $navbar-a-fontSize: var(--NavData-a-fontSize);
    $navbar-a-fontStyle: var(--NavData-a-fontStyle);
    $navbar-a-fontBold: var(--NavData-a-fontBold);

    $slider-color-a-one: var(--SliderData-color-a-one);
    $slider-color-a-two: var(--SliderData-color-a-two);
    $slider-color-a-three: var(--SliderData-color-a-three);
    $slider-color-a-four: var(--SliderData-color-a-four);
    $slider-color-b-one: var(--SliderData-color-b-one);
    $slider-color-b-two: var(--SliderData-color-b-two);
    $slider-a-fontFamily: var(--SliderData-a-fontFamily);
    $slider-a-fontSize: var(--SliderData-a-fontSize);
    $slider-a-fontStyle: var(--SliderData-a-fontStyle);
    $slider-a-fontBold: var(--SliderData-a-fontBold);
    $slider-b-fontFamily: var(--SliderData-b-fontFamily);
    $slider-b-fontSize: var(--SliderData-b-fontSize);
    $slider-b-fontStyle: var(--SliderData-b-fontStyle);
    $slider-b-fontBold: var(--SliderData-b-fontBold);

    $story-color-a-one: var(--StoryData-color-a-one);
    $story-color-b-one: var(--StoryData-color-b-one);
    $story-color-b-two: var(--StoryData-color-b-two);
    $story-color-c-one: var(--StoryData-color-c-one);
    $story-color-c-two: var(--StoryData-color-c-two);
    $story-a-fontFamily: var(--StoryData-a-fontFamily);
    $story-a-fontSize: var(--StoryData-a-fontSize);
    $story-a-fontStyle: var(--StoryData-a-fontStyle);
    $story-a-fontBold: var(--StoryData-a-fontBold);
    $story-b-fontFamily: var(--StoryData-b-fontFamily);
    $story-b-fontSize: var(--StoryData-b-fontSize);
    $story-b-fontStyle: var(--StoryData-b-fontStyle);
    $story-b-fontBold: var(--StoryData-b-fontBold);

    $gallery-color-a-one: var(--GalleryData-color-a-one);
    $gallery-color-a-two: var(--GalleryData-color-a-two);
    $gallery-color-b-one: var(--GalleryData-color-b-one);
    $gallery-color-b-two: var(--GalleryData-color-b-two);
    $gallery-a-fontFamily: var(--GalleryData-a-fontFamily);
    $gallery-a-fontSize: var(--GalleryData-a-fontSize);
    $gallery-a-fontStyle: var(--GalleryData-a-fontStyle);
    $gallery-a-fontBold: var(--GalleryData-a-fontBold);

    $counter-bg-img: var(--CounterData-bg-img);
    $counter-bg-img-opacity: var(--CounterData-bg-img-opacity);
    $counter-color-a-one: var(--CounterData-color-a-one);
    $counter-color-a-one: var(--CounterData-color-a-one);
    $counter-color-a-two: var(--CounterData-color-a-two);
    $counter-color-a-three: var(--CounterData-color-a-three);
    $counter-color-b-one: var(--CounterData-color-b-one);
    $counter-color-b-two: var(--CounterData-color-b-two);
    $counter-a-fontFamily: var(--CounterData-a-fontFamily);
    $counter-a-fontSize: var(--CounterData-a-fontSize);
    $counter-a-fontStyle: var(--CounterData-a-fontStyle);
    $counter-a-fontBold: var(--CounterData-a-fontBold);
    $counter-b-fontFamily: var(--CounterData-b-fontFamily);
    $counter-b-fontSize: var(--CounterData-b-fontSize);
    $counter-b-fontStyle: var(--CounterData-b-fontStyle);
    $counter-b-fontBold: var(--CounterData-b-fontBold);

    $wedparty-color-a-one: var(--WeddingPartyData-color-a-one);
    $wedparty-color-a-two: var(--WeddingPartyData-color-a-two);
    $wedparty-color-a-three: var(--WeddingPartyData-color-a-three);
    $wedparty-color-a-four: var(--WeddingPartyData-color-a-four);
    $wedparty-color-b-one: var(--WeddingPartyData-color-b-one);
    $wedparty-color-b-two: var(--WeddingPartyData-color-b-two);
    $wedparty-color-b-three: var(--WeddingPartyData-color-b-three);
    $wedparty-color-b-four: var(--WeddingPartyData-color-b-four);
    $wedparty-color-c-one: var(--WeddingPartyData-color-c-one);
    $wedparty-color-c-two: var(--WeddingPartyData-color-c-two);
    $wedparty-color-c-three: var(--WeddingPartyData-color-c-three);
    $wedparty-color-c-four: var(--WeddingPartyData-color-c-four);
    $wedparty-a-fontFamily: var(--WeddingPartyData-a-fontFamily);
    $wedparty-a-fontSize: var(--WeddingPartyData-a-fontSize);
    $wedparty-a-fontStyle: var(--WeddingPartyData-a-fontStyle);
    $wedparty-a-fontBold: var(--WeddingPartyData-a-fontBold);
    $wedparty-b-fontFamily: var(--WeddingPartyData-b-fontFamily);
    $wedparty-b-fontSize: var(--WeddingPartyData-b-fontSize);
    $wedparty-b-fontStyle: var(--WeddingPartyData-b-fontStyle);
    $wedparty-b-fontBold: var(--WeddingPartyData-b-fontBold);
    $wedparty-c-fontFamily: var(--WeddingPartyData-c-fontFamily);
    $wedparty-c-fontSize: var(--WeddingPartyData-c-fontSize);
    $wedparty-c-fontStyle: var(--WeddingPartyData-c-fontStyle);
    $wedparty-c-fontBold: var(--WeddingPartyData-c-fontBold);

    $agenda-color-a-one: var(--AgendaData-color-a-one);
    $agenda-color-a-two: var(--AgendaData-color-a-two);
    $agenda-color-a-three: var(--AgendaData-color-a-three);
    $agenda-color-a-four: var(--AgendaData-color-a-four);
    $agenda-color-b-one: var(--AgendaData-color-b-one);
    $agenda-color-b-two: var(--AgendaData-color-b-two);
    $agenda-color-b-three: var(--AgendaData-color-b-three);
    $agenda-color-b-four: var(--AgendaData-color-b-four);
    $agenda-color-c-one: var(--AgendaData-color-c-one);
    $agenda-color-c-two: var(--AgendaData-color-c-two);
    $agenda-color-c-three: var(--AgendaData-color-c-three);
    $agenda-color-c-four: var(--AgendaData-color-c-four);
    $agenda-a-fontFamily: var(--AgendaData-a-fontFamily);
    $agenda-a-fontSize: var(--AgendaData-a-fontSize);
    $agenda-a-fontStyle: var(--AgendaData-a-fontStyle);
    $agenda-a-fontBold: var(--AgendaData-a-fontBold);
    $agenda-b-fontFamily: var(--AgendaData-b-fontFamily);
    $agenda-b-fontSize: var(--AgendaData-b-fontSize);
    $agenda-b-fontStyle: var(--AgendaData-b-fontStyle);
    $agenda-b-fontBold: var(--AgendaData-b-fontBold);
    $agenda-c-fontFamily: var(--AgendaData-c-fontFamily);
    $agenda-c-fontSize: var(--AgendaData-c-fontSize);
    $agenda-c-fontStyle: var(--AgendaData-c-fontStyle);
    $agenda-c-fontBold: var(--AgendaData-c-fontBold);

    $social-color-a-one: var(--SocialData-color-a-one);
    $social-color-a-two: var(--SocialData-color-a-two);
    $social-color-b-one: var(--SocialData-color-b-one);
    $social-color-b-two: var(--SocialData-color-b-two);
    $social-a-fontFamily: var(--SocialData-a-fontFamily);
    $social-a-fontSize: var(--SocialData-a-fontSize);
    $social-a-fontStyle: var(--SocialData-a-fontStyle);
    $social-a-fontBold: var(--SocialData-a-fontBold);

    $footer-color-a-one: var(--FooterData-color-a-one);
    $footer-color-a-two: var(--FooterData-color-a-two);
    $footer-color-a-three: var(--FooterData-color-a-three);
    $footer-color-b-one: var(--FooterData-color-b-one);
    $footer-color-b-two: var(--FooterData-color-b-two);
    $footer-color-b-three: var(--FooterData-color-b-three);
    $footer-color-b-four: var(--FooterData-color-b-four);
    $footer-a-fontFamily: var(--FooterData-a-fontFamily);
    $footer-a-fontSize: var(--FooterData-a-fontSize);
    $footer-a-fontStyle: var(--FooterData-a-fontStyle);
    $footer-a-fontBold: var(--FooterData-a-fontBold);
    $footer-b-fontFamily: var(--FooterData-b-fontFamily);
    $footer-b-fontSize: var(--FooterData-b-fontSize);
    $footer-b-fontStyle: var(--FooterData-b-fontStyle);
    $footer-b-fontBold: var(--FooterData-b-fontBold);
    $footer-c-fontFamily: var(--FooterData-c-fontFamily);
    $footer-c-fontSize: var(--FooterData-c-fontSize);
    $footer-c-fontStyle: var(--FooterData-c-fontStyle);
    $footer-c-fontBold: var(--FooterData-c-fontBold);

    $map-color-a-one: var(--MapData-color-a-one);
    $map-color-b-one: var(--MapData-color-b-one);
    $map-color-b-two: var(--MapData-color-b-two);
    $map-c-fontFamily: var(--MapData-c-fontFamily);
    $map-c-fontSize: var(--MapData-c-fontSize);
    $map-c-fontStyle: var(--MapData-c-fontStyle);
    $map-c-fontBold: var(--MapData-c-fontBold);
    $map-b-fontFamily: var(--MapData-b-fontFamily);
    $map-b-fontSize: var(--MapData-b-fontSize);
    $map-b-fontStyle: var(--MapData-b-fontStyle);
    $map-b-fontBold: var(--MapData-b-fontBold);

    @import "slider";


    #agenda {
        @import "schedule";
    }

    #weddingparty {
        @import "weddingparty";
    }

    @import "navbarheader";

    @import "gallery";
    @import "counter";
    @import "footer";
    @import "social";
    @import "story";
    @import "video";
    @import "setting";
    @import "map";
}