/*Counter Section*/
.ast-countdown {
    text-align: center;
}
#counter {
    background: $counter-color-a-one;
}

.ast-count-row {
    margin-top: 80px !important;
    margin-bottom: 50px;
}

.ast-count-circle {
    text-align: center;
    background: $counter-color-b-one;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    color: $counter-color-b-two;
    font-family: $counter-a-fontFamily;
    font-size: $counter-a-fontSize;
    font-style: $counter-a-fontStyle;
    font-weight: $counter-a-fontBold;
    margin:auto;
}

.ast-count-animated-circle {
    width: 140px;
    height: 140px;
    background: $counter-color-b-one;
    opacity: 0.6;
    border-radius: 50%;
    position: absolute;
    left: 0%;
    top: 0%;
    animation-name: pluse;
    animation-duration: 1.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    z-index: -1;
}

@keyframes pluse {
    0% {
        transform: scale(.8);
    }

    50% {
    }

    100% {
        transform: scale(1.2);
    }
}
