:root {
    --NavData-color-a-one: '';
    --NavData-color-b-one: '';
    --NavData-color-b-two: '';
    --NavData-color-c-one: '';
    --NavData-color-c-two: '';
    --NavData-color-c-three: '';
    --NavData-a-fontFamily: '';
    --NavData-a-fontSize: '';
    --NavData-a-fontStyle: '';
    --NavData-a-fontBold: '';
    --AboutData-color-a-one: '';
    --AboutData-color-b-one: '';
    --AboutData-color-b-two: '';
    --AboutData-color-b-three: '';
    --AboutData-color-b-four: '';
    --AboutData-color-c-one: '';
    --AboutData-color-c-two: '';
    --AboutData-color-c-three: '';
    --AboutData-color-c-four: '';
    --AboutData-a-fontFamily: '';
    --AboutData-b-fontFamily: '';
    --AboutData-c-fontFamily: '';
    --HeaderData-bg-img: '';
    --HeaderData-bg-img-opacity: '';
    --HeaderData-a-fontFamily: '';
    --HeaderData-a-fontSize: '';
    --HeaderData-a-fontStyle: '';
    --HeaderData-b-fontFamily: '';
    --HeaderData-b-fontSize: '';
    --HeaderData-b-fontStyle: '';
    --HeaderData-color-a-one: '';
    --HeaderData-color-a-two: '';
    --SpeakerData-bg-img: '';
    --SpeakerData-bg-img-opacity: '';
    --SpeakerData-color-a-one: '';
    --SpeakerData-color-a-two: '';
    --SpeakerData-color-a-three: '';
    --SpeakerData-color-b-one: '';
    --SpeakerData-color-b-two: '';
    --SpeakerData-color-b-three: '';
    --SpeakerData-color-b-four: '';
    --SpeakerData-color-c-one: '';
    --SpeakerData-color-c-two: '';
    --SpeakerData-color-c-three: '';
    --SpeakerData-a-fontFamily: '';
    --SpeakerData-b-fontFamily: '';
    --SpeakerData-c-fontFamily: '';
    --SponsorData-color-a-one: '';
    --AgendaData-color-a-one: '';
    --AgendaData-color-a-two: '';
    --AgendaData-color-a-three: '';
    --AgendaData-color-a-four: '';
    --AgendaData-color-b-one: '';
    --AgendaData-color-b-two: '';
    --AgendaData-color-b-three: '';
    --AgendaData-color-b-four: '';
    --AgendaData-color-c-one: '';
    --AgendaData-color-c-two: '';
    --AgendaData-color-c-three: '';
    --AgendaData-color-c-four: '';
    --AgendaData-a-fontFamily: '';
    --AgendaData-b-fontFamily: '';
    --AgendaData-c-fontFamily: '';
    --PricingData-color-a-one: '';
    --PricingData-color-a-two: '';
    --PricingData-color-a-three: '';
    --PricingData-color-b-one: '';
    --PricingData-color-b-two: '';
    --PricingData-color-b-three: '';
    --PricingData-color-b-four: '';
    --PricingData-color-c-one: '';
    --PricingData-color-c-two: '';
    --PricingData-color-c-three: '';
    --PricingData-color-c-four: '';
    --PricingData-a-fontFamily: '';
    --PricingData-b-fontFamily: '';
    --PricingData-c-fontFamily: '';
    --NewsLetterData-bg-img: '';
    --NewsLetterData-bg-img-opacity: '';
    --NewsLetterData-color-a-one: '';
    --NewsLetterData-color-a-two: '';
    --NewsLetterData-color-b-one: '';
    --NewsLetterData-color-b-two: '';
    --NewsLetterData-color-b-three: '';
    --NewsLetterData-a-fontFamily: '';
    --NewsLetterData-b-fontFamily: '';
    --FooterData-color-a-one: '';
    --FooterData-color-a-two: '';
    --FooterData-color-a-three: '';
    --FooterData-color-b-one: '';
    --FooterData-color-b-two: '';
    --FooterData-color-b-three: '';
    --FooterData-color-b-four: '';
    --FooterData-a-fontFamily: '';
    --FooterData-b-fontFamily: '';
    --FooterData-c-fontFamily: '';
    --MapData-color-a-one: '';
    --MapData-color-b-one: '';
    --MapData-color-b-two: '';
    --MapData-c-fontFamily: '';
    --MapData-c-fontSize: '';
    --MapData-c-fontStyle: '';
    --MapData-c-fontBold: '';
    --MapData-b-fontFamily: '';
    --MapData-b-fontSize: '';
    --MapData-b-fontStyle: '';
    --MapData-b-fontBold: '';
}

//About Section
$navbar-color-a-one: '';
$navbar-color-b-one: '';
$navbar-color-b-two: '';
$navbar-color-c-one: '';
$navbar-color-c-two: '';
$navbar-color-c-three: '';
$navbar-a-fontFamily: '';
$navbar-a-fontSize: '';
$navbar-a-fontStyle: '';
$navbar-a-fontBold: '';

//About Section
$about-color-a-one: '';
$about-color-b-one: '';
$about-color-b-two: '';
$about-color-b-three: '';
$about-color-b-four: '';
$about-color-c-one: '';
$about-color-c-two: '';
$about-color-c-three: '';
$about-color-c-four: '';

$about-a-fontFamily: '';
$about-b-fontFamily: '';
$about-c-fontFamily: '';

//Header/Intro Section
$header-bg-img: url('../imgs/header-bg.jpg');
$header-bg-img-opacity: 0.6;
$header-color-a-one: '';
$header-color-a-two: '';
$header-a-fontFamily: '';
$header-a-fontSize: '';
$header-a-fontStyle: '';
$header-b-fontFamily: '';
$header-b-fontSize: '';
$header-b-fontStyle: '';

//Speaker Section
$speaker-bg-img: url(../imgs/speaker-bg.jpg);
$speaker-bg-img-opacity: 0.6;
$speaker-color-a-one: '';
$speaker-color-a-two: '';
$speaker-color-a-three: '';
$speaker-color-b-one: '';
$speaker-color-b-two: '';
$speaker-color-b-three: '';
$speaker-color-b-four: '';
$speaker-color-c-one: '';
$speaker-color-c-two: '';
$speaker-color-c-three: '';

$speaker-a-fontFamily: '';
$speaker-b-fontFamily: '';
$speaker-c-fontFamily: '';

//Agenda Section
$agenda-color-a-one: '';
$agenda-color-a-two: '';
$agenda-color-a-three: '';
$agenda-color-a-four: '';
$agenda-color-b-one: '';
$agenda-color-b-two: '';
$agenda-color-b-three: '';
$agenda-color-b-four: '';
$agenda-color-c-one: '';
$agenda-color-c-two: '';
$agenda-color-c-three: '';
$agenda-color-c-four: '';
$agenda-a-fontFamily: '';
$agenda-b-fontFamily: '';
$agenda-c-fontFamily: '';

//Pricing Section
$pricing-color-a-one: '';
$pricing-color-a-two: '';
$pricing-color-a-three: '';
$pricing-color-b-one: '';
$pricing-color-b-two: '';
$pricing-color-b-three: '';
$pricing-color-b-four: '';
$pricing-color-c-one: '';
$pricing-color-c-two: '';
$pricing-color-c-three: '';
$pricing-color-c-four: '';
$pricing-a-fontFamily: '';
$pricing-b-fontFamily: '';
$pricing-c-fontFamily: '';

$sponsor-color-a-one: '';

$newsletter-bg-img: url(../imgs/callaction-bg.jpg);
$newsletter-color-a-one: '';
$newsletter-bg-img-opacity: 0.6;
$newsletter-color-a-two: '';
$newsletter-color-b-one: '';
$newsletter-color-b-two: '';
$newsletter-color-b-three: '';
$newsletter-a-fontFamily: '';
$newsletter-b-fontFamily: '';

//Pricing Section
$footer-color-a-one: '';
$footer-color-a-two: '';
$footer-color-a-three: '';
$footer-color-b-one: '';
$footer-color-b-two: '';
$footer-color-b-three: '';
$footer-color-b-four: '';
$footer-a-fontFamily: '';
$footer-b-fontFamily: '';
$footer-c-fontFamily: '';

$Map-color-a-one: '';
$Map-color-b-one: '';
$Map-color-b-two: '';
$Map-b-fontFamily: '';
$Map-b-fontSize: '';
$Map-b-fontStyle: '';
$Map-b-fontBold: '';
$Map-c-fontFamily: '';
$Map-c-fontSize: '';
$Map-c-fontStyle: '';
$Map-c-fontBold: '';
