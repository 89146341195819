.price-area {
    background: $pricing-color-a-one;
}

.heading-h1 {
    font-family: $pricing-a-fontFamily;
    color: $pricing-color-a-two;
    line-height: 1.2em !important;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 600;
    font-size: 36px;
}

.heading-p {
    color: $pricing-color-a-three;
    font-family: $pricing-b-fontFamily;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.625em;
    margin-top: 15px;
    margin-bottom: 80px;
}

.menu-content p {
    //font-family: $pricing-font-family-primary;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.625em;
    margin-top: 15px;
    margin-bottom: 80px;
}
.cn2-price-box p {
    //font-family: $pricing-font-family-primary;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.625em;
    margin-top: 10px;
}

.cn2-price-box h4 {
   // font-family: $pricing-font-family-primary;
    color: #222;
    line-height: 1.2em !important;
    margin-bottom: 0;
    margin-top: 20px;
    font-weight: 600;
}

.cn2-price-box {
    font-family: $pricing-c-fontFamily !important;
}

.price-area .single-price {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-align: center;
    background-color: $pricing-color-b-one;
    border-radius: 5px;
    padding: 40px 20px;
}

.price-area .single-price .package-no {
    font-family: $pricing-c-fontFamily;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    font-weight: 300;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    height: 140px;
    background-color: $pricing-color-b-two;
    border-radius: 100px;
    align-items: center;
    display: grid;
    border: 30px solid $pricing-color-b-three;
    margin-bottom: 0px !important;
}

.price-area .single-price .top-part h4 {
    margin-top: 20px;
    font-family: $pricing-c-fontFamily ;
}

.price-area .single-price .package-list {
    padding: 30px 0
}

.price-area .single-price .package-list ul li {
    padding: 10px 0;
    border-top: 1px solid #eee;
    font-family: $pricing-c-fontFamily;
    color: $pricing-color-b-four;
}

.price-area .single-price:hover .package-list ul li {

    color: $pricing-color-c-four;
}

.price-area .single-price .package-list ul li:last-child {
    border-bottom: 1px solid #eee
}

.price-area .single-price .bottom-part {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.price-area .single-price .bottom-part .price-btn {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background-color: #fff;
    color: black;
    margin-top: 40px;
    padding: 10px 50px;
    border-radius: 5px;
    font-weight: 600;
    display: inline-block
}

.price-area .single-price:hover {
    transform: scale(1.1);
    z-index: 2;
    background-color: $pricing-color-c-one;
    color: $pricing-color-c-four;
    cursor: pointer
}

.price-area .single-price:hover h1, .price-area .single-price:hover h4, .price-area .single-price:hover p {
    color: $pricing-color-c-four;
}

.price-area .single-price h1, .price-area .single-price h4, p {
    color: $pricing-color-b-four;
    font-family: $pricing-c-fontFamily;
}

.price-area .single-price:hover .package-no {
    color: $pricing-color-c-four;
    border-color: $pricing-color-c-three;
    background-color: $pricing-color-c-two;
}

@media (max-width: 960px) {
    .price-area .single-price {
        margin-bottom: 30px
    }
}

@media (max-width: 736px) {
    .price-area .single-price {
        margin: 10px
    }
}
