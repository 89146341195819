/* :: 7.0 Header Area CSS */
a{
    text-decoration:none;
}
.cn2-navLogo {
    width:210px;
    height:40px;
    margin-left: 10px;
}

.btn-margin{
    margin-left: auto !important;
}

.cn2-header {
    background: $navbar-color-a-one;
    width: 100%;
    transition: all 0.4s linear 0s;
}

.cn2-header1 {
    background: $navbar-color-a-one;
    padding: 0;
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
}

.navbar-toggler {
    background-color: orange !important;
    color: white !important;
    border-radius: 0px !important;
    margin-top: 11px;
}

button.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

#offcanvasNavbar-expand-lg {
    background-color: rgb(0 0 0 / 90%);
}

.offcanvas-title {
    font-weight: 700 !important;
    color: white;
    padding: 10px;
}

.btn-close {
    opacity: 2.5 !important;
}

.cn2-navlink {
    font-family: $navbar-a-fontFamily;
    padding: 6px 25px !important;
    text-decoration: none;
    display: inline-block;
    color: $navbar-color-b-one;
    font-weight: 600 !important;
    font-size: $navbar-a-fontSize !important;
    font-style: $navbar-a-fontStyle;
    font-weight: $navbar-a-fontBold;
    text-transform: uppercase;
    outline: none;
    letter-spacing: 2px !important;
}

.cn2-btn {
    float: right;
    background: $navbar-color-c-one;
    padding: 6px 20px !important;
    border: 1px solid $navbar-color-c-one;
    margin-left: 41px !important;
    color: $navbar-color-c-three !important;
    font-family: $navbar-a-fontFamily;
}

.cn2-btn:hover {
    border: 1px solid $navbar-color-c-two;
    padding: 6px 20px !important;
    background: $navbar-color-c-two;
    color: $navbar-color-c-three !important;
}


.cn2-navlink:hover {
    color: $navbar-color-b-two;
}

.cn2-navlink.active {
    color: white !important;
}

.cn2-search-icon {
    font-size: 18px !important;
}
.navbar-expand-lg .offcanvas .offcanvas-body {
    display: block !important;
    }

    .cn2-navbar-nav {
        padding: 10px 0px;
    }

.cn2-navbar-navShrink {
    padding: 10px 0px;
}


    @media screen and (max-width: 990px) {
        .cn2-navbar-nav {
            padding-left: 0px !important;
        }

        .cn2-navbar-navShrink {
            padding-left: 0px !important;
        }
    }

    @media screen and (min-width: 991px) {

        .cn2-navbar-navShrink {
            padding: 10px 0px;
        }
    }
