/* :: 7.0 Header Area CSS */

.cnst-under-heading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cnst-under-heading-cricle {
    background-color: #34a997;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 50%;
    animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px #76bfb496;
    }

    100% {
        box-shadow: 0 0 0 4px #76bfb496;
    }
}

.cnst-navLogoText {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 4px;
    margin-left: 10px;
}

.cnst-header {
    position: absolute;
    z-index: 999;
    background-color: rgb(0 0 0 / 47%);
    left: 0;
    top: 0;
    width: 100%;
    transition: all 0.4s linear 0s;
}

.cnst-header1 {
    background: black;
    padding: 0;
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
}

.navbar-toggler {
    background-color: $color-secondary !important;
    color: white !important;
    border-radius: 0px !important;
    margin-top: 11px;
}

button.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

#offcanvasNavbar-expand-lg {
    background-color: rgb(0 0 0 / 90%);
}

.offcanvas-title {
    font-weight: 700 !important;
    color: white;
    padding: 10px;
}

.btn-close {
    opacity: 2.5 !important;
}

.cnst-navlink {
    font-family: $font-family-primary !important;
    font-size: 15px !important;
    line-height: 20.4px;
    color: #ffffff;
    padding: 23px 23px 24px !important;
    margin: 0;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px !important;
    font-weight: 700 !important;
}

.cnst-navlink:hover {
    color: $color-secondary !important;
}

.cnst-navlink.active {
    color: white !important;
}

.cnst-search-icon {
    font-size: 18px !important;
}

.cnst-navbar-nav {
    padding: 20px 20px;
    padding-left: 114px !important;
    margin-top: 15px;
}

@media screen and (max-width: 990px) {
    .cnst-navbar-nav {
        padding-left: 0px !important;
    }

    .cnst-navbar-navShrink {
        padding-left: 0px !important;
    }
}

@media screen and (min-width: 991px) {

    .cnst-navbar-navShrink {
        padding: 0px 0px;
        padding-left: 114px !important;
    }
}

.cnst-confer-btn {
    margin-left: 56px !important;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    display: inline-block;
    padding: 0 25px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #ffffff;
    border-radius: 23px;
    /* transition-duration: 500ms; */
    /* overflow: hidden; */
    /* transition-duration: 800ms; */
    /* width: 120%; */
    /* height: 120%; */
    /* top: -10%; */
    /* left: -10%; */
    content: '';
    background: #df42b1;
    background-image: -webkit-linear-gradient(120deg, $color-secondary 0%, #505add 100%);
    margin: 12px 0px;
}

.cnst-confer-btn:hover {
    color: white;
    background-image: -webkit-linear-gradient(120deg, #505add 0%, $color-secondary 100%);
}